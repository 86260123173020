@import "src/utils/reset";
@import "src/utils/mixins";

.modal {
  width: 70vw;
  max-width: 1100px;
  padding: 48px;
  border-radius: 4px;
  border: 1px solid var(--border);
  background: var(--background-light);
  box-shadow: 0 2px 4px -2px rgba(255, 255, 255, 0.06), 0 4px 8px -2px rgba(255, 255, 255, 0.04);
  @include flex_center;
  flex-direction: column;
  gap: 48px;

  @media screen and (max-width: 1024px) {
    width: 90vw;
  }

  @media screen and (425px <= width <= 768px) {
    padding: 24px;
  }

  @media screen and (max-width: 425px) {
    padding: 24px 16px;
  }

  &_content {
    @include flex_center;
    display: flex;
    flex-direction: column;
    gap: 32px;

    &_description {
      @include flex_center;
      flex-direction: column;
      gap: 16px;

      & > h2 {
        @include text(var(--white), 30px, 600, 133.333%);
        text-align: center;
      }

      & > p {
        @include text(var(--white), 18px, 400, 133.333%);
        text-align: center;
      }
    }

    &_buttons {
      width: 100%;
      @include flex_center;
      gap: 24px;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
    }
  }
}
