@import "src/utils/reset";

.wrapper {
  @include page_box;

  &_myAccountLayout {
    @include container_max_width;
    display: flex;
    gap: 32px;
    flex: 1;
  }
}
