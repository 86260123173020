@import "src/utils/reset";

// --------------------------------- desktop ---------------------------------

.newProducts_desktop {
  display: flex;
  flex-direction: column;
  gap: 56px;

  @media screen and (max-width: 1280px) {
    display: none;
  }

  &_title {
    @include block_title;
  }

  &_content {
    display: flex;
    gap: 32px;

    &_products {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 32px;

      @media screen and (max-width: 1280px) {
        display: none;
      }

      &_six {
        display: none;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: auto;
        gap: 32px;

        @media screen and (max-width: 1280px) {
          display: grid;
        }
      }
    }

    &_innovations {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;

      @media screen and (max-width: 1280px) {
        flex-direction: row;
      }

      &_icon {
        width: 100%;
      }

      &_description {
        padding-top: 8px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 32px;

        &_text {
          display: flex;
          gap: 32px;

          @media screen and (max-width: 1280px) {
            flex-direction: column-reverse;
          }

          & > p {
            @include text(var(--white), 16px, 400, 150%);
          }
        }
      }
    }
  }
}

// --------------------------------- mobile ---------------------------------

.newProducts_mobile {
  display: none;

  @media screen and (max-width: 1280px) {
    display: block;
  }

  &_spinner {
    width: 100%;
    height: 150px;
    @include flex_center;
    margin: 24px 0;
  }

  &_innovations {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &_icon {
      width: 100%;
    }

    & > p {
      @include text(var(--white), 14px, 400, 171.429%);
    }
  }
}
