@import "src/utils/reset";

.reportPage {
  display: flex;
  flex-direction: column;
  gap: 48px;
  overflow-y: auto;

  &_title {
    display: flex;
    align-items: center;
    gap: 16px;

    h1 {
      @include block_title;
    }

    img {
      display: flex;

      @media screen and (min-width: 768px) {
        display: none;
      }
    }
  }

  &_content {
    display: flex;
    flex-direction: column;
    gap: 48px;
    overflow-y: auto;

    &_dates {
      display: flex;
      align-items: center;
      gap: 8px;

      &_divider {
        @include text(var(--white), 14px, 400, 171.429%);
      }
    }
  }
}
