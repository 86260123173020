@import "src/utils/reset";

.item {
  padding: 24px 16px;
  border-radius: 4px;
  background: var(--background-light);
  display: flex;
  align-items: center;
  gap: 32px;

  @media screen and (max-width: 768px) {
    padding: 16px;
    flex-direction: column;
    gap: 24px;
  }

  &_description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;

    @media screen and (max-width: 768px) {
      gap: 16px;
    }

    h3 {
      @include text(var(--white), 24px, 400, 133.333%);
      text-transform: uppercase;
    }

    p {
      @include text(var(--white), 14px, 400, 171.429%);
    }
  }
}
