@import "src/utils/reset";

.wrapper {
  @include page_box;

  &_confirmOrderPage {
    @include container_max_width;
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 32px;

    &_title {
      @include main_title;
      margin-bottom: 8px;

      @media screen and (max-width: 425px) {
        margin: 0;
      }
    }

    &_tabs {
      display: flex;
      align-items: flex-start;
      gap: 24px;

      @media screen and (max-width: 375px) {
        flex-direction: column;
        gap: 16px;
      }

      &_item {
        padding: 8px 16px;
        border-radius: 4px;
        border: 1px solid #999;
        @include text(#999, 16px, 500, 150%);
        transition: 0.3s;

        &.active {
          border: 1px solid var(--white);
          color: var(--white);
          transition: 0.3s;
        }

        @media screen and (max-width: 425px) {
          width: 100%;
          text-align: center;
        }
      }
    }

    &_row {
      display: flex;
      align-items: flex-start;
      gap: 32px;

      @media screen and (max-width: 1280px) {
        flex-direction: column;
      }
    }
  }
}
