@import "src/utils/reset";

.toggle {
  background: var(--white);
  border-radius: 4px;
  padding: 4px;
  display: flex;

  & > div {
    padding: 4px;
    cursor: pointer;

    &.active {
      background: var(--background-light);
      border-radius: 4px;
    }
  }
}
