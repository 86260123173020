@import "src/utils/reset";

.offeredProducts {
  &_tabs {
    display: flex;

    &_item {
      width: 100%;
      //width: 50%;
      padding: 8px 16px;
      border-bottom: 1px solid var(--border);
      text-align: center;
      @include text(var(--white), 20px, 600, 171.429%);
      transition: 0.3s;

      &.active {
        color: var(--main-red);
        transition: 0.3s;
        border-bottom: 1px solid var(--main-red);
      }

      @media screen and (max-width: 425px) {
        font-size: 14px;
      }
    }
  }

  &_additionalPoints {
    padding-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
}
