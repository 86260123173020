@import "../../utils/reset";

.button {
  position: relative;
  overflow: hidden;
  padding: 12px 40px;
  border-radius: 4px;
  background: var(--main-red);
  cursor: pointer;
  @include text(var(--white), 16px, 700, 150%);
  text-align: center;
  transition: 0.3s;

  &.disabled {
    opacity: 0.6;
    transition: 0.3s;
    cursor: auto;
  }

  .ripple {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.1);
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.5s linear;

    &.disabled {
      width: 0;
      height: 0;
    }
  }

  &:hover {
    .ripple {
      transform: scaleX(1);
    }
  }
}
