@import "src/utils/reset";

.deliveryAddress {
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.90);

  &_title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_name {
      color: var(--background-dark);
      font-size: 14px;
      font-weight: 600;
      line-height: 171.429%;
    }

    &_delete {
      @include text(var(--main-red), 14px, 500, 171.429%);
      cursor: pointer;
    }
  }

  &_block {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 8px;

    &_subtitle {
      @include text(var(--background-dark), 14px, 400, 171.429%);
    }

    &_data {
      @include text(var(--background-dark), 14px, 500, 171.429%);

      &.city {
        font-weight: 600;
      }
    }
  }
}
