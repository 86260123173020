@import "src/utils/reset";

.item {
  padding: 24px 16px;
  border-radius: 4px;
  background: var(--white);
  display: flex;
  flex-direction: column;
  border: 1px solid transparent;
  gap: 24px;
  transition: 0.3s;

  &:hover {
    border: 1px solid var(--white);
    background: transparent;
    transition: 0.3s;
    transform: scale(0.95);

    .item_description {
      & > h3 {
        @include text(var(--white), 24px, 600, 133.33%);
      }

      & > p {
        @include text(var(--white), 14px, 400, 150%);
      }
    }
  }

  & > img {
    width: 48px;
    height: 48px;
  }

  &_description {
    display: flex;
    flex-direction: column;
    gap: 8px;

    & > h3 {
      @include text(var(--background-dark), 24px, 600, 133.33%);
    }

    & > p {
      @include text(var(--background-dark), 14px, 400, 150%);
    }
  }
}
