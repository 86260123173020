@import "../../utils/reset";

.preloader-wrapper {
  width: 100%;
  min-height: 100vh;
  background: var(--background-dark);
  @include flex_center;
}

.preloader {
  max-width: 20em;
}

.heart {
  display: block;
  margin: 0 auto 1.5em auto;
  width: 8em;
  height: 8em;
}

.heart__lines,
.heart__top {
  animation: heartLines 2s ease-in-out infinite;
}

.heart__lines {
  stroke: var(--main-red);
}

.heart__top {
  animation-name: heartTop;
}

.heart__track {
  stroke: hsla(var(--hue), 10%, 10%, 0.1);
  transition: stroke var(--trans-dur);
}

/* Dark theme */
@media (prefers-color-scheme: dark) {
  :root {
    --bg: hsl(var(--hue), 10%, 10%);
    --fg: hsl(var(--hue), 10%, 90%);
  }
  .heart__track {
    stroke: hsla(var(--hue), 10%, 90%, 0.1);
  }
}

/* Animations */
@keyframes msg {
  from {
    opacity: 1;
    visibility: visible;
  }
  99.9% {
    opacity: 0;
    visibility: visible;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes heartLines {
  from,
  to {
    opacity: 0;
  }
  8%,
  92% {
    opacity: 1;
  }
}

@keyframes heartTop {
  from {
    stroke-dashoffset: -338;
  }
  50% {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: 338;
  }
}
