@import "src/utils/reset";

.loginPage {
  min-width: 100%;
  padding: 48px 64px;
  background: var(--background-dark);
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 48px;

  @media screen and (425px <= width <= 768px) {
    padding: 48px 32px;
  }

  @media screen and (max-width: 425px) {
    padding: 32px 16px;
  }

  &_title {
    @include main_title;
  }
}
