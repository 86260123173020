@import "src/utils/mixins";

.productCard {
  width: 300px;
  min-width: 100%;
  min-height: 412px;
  padding: 16px;
  border-radius: 4px;
  background: var(--background-light);
  border: 1px solid transparent;
  transition: 0.3s;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  cursor: pointer;

  &:hover {
    @media screen and (min-width: 768px) {
      border: 1px solid var(--border);
      transition: 0.3s;

      .productCard_favourite {
        @include flex_center;
      }

      .productCard_description_row {
        @media screen and (min-width: 768px) {
          display: none;
        }
      }

      .productCard_description_price {
        display: none;
      }

      .productCard_description_buttons {
        display: flex;
      }

      .productCard_description_pricesSale {
        display: none;
      }
    }
  }

  &_photo {
    width: 100%;
    height: 225px;
    @include flex_center;

    & > img {
      max-width: 100%;
      max-height: 100%;
      border-radius: 2px;
    }
  }

  &_favourite {
    width: 44px;
    height: 44px;
    display: none;
    padding: 8px;
    position: absolute;
    top: 24px;
    right: 24px;
    background: var(--white);
    border-radius: 50%;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      transition: 0.3s;
      transform: scale(0.9);
    }
  }

  &_sale {
    position: absolute;
    top: 0;
    left: 0;
    right: 25%;
    background: linear-gradient(-45deg, transparent 50%, var(--main-yellow) 50%);
    border-radius: 4px 0 0 0;
    padding: 8px;

    & > p {
      @include text(var(--background-light), 16px, 700, 150%);
      font-family: 'Open Sans', sans-serif;
    }
  }

  &_promo {
    position: absolute;
    top: 0;
    left: 0;
    right: 25%;
    background: linear-gradient(-45deg, transparent 50%, var(--main-red) 50%);
    border-radius: 4px 0 0 0;
    padding: 8px;

    & > p {
      @include text(var(--white), 16px, 700, 150%);
      font-family: 'Open Sans', sans-serif;
    }
  }

  &_description {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &_row {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & > button {
        display: none;

        @media screen and (max-width: 768px) {
          display: flex;
        }
      }
    }

    &_title {
      display: flex;
      flex-direction: column;

      &_points {
        @include text(var(--white), 14px, 400, 171.429%);
      }

      &_content {
        max-height: 48px;
        @include text(var(--white), 16px, 700, 150%);
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }

    &_pricesSale {
      display: flex;
      flex-direction: column;

      &_old {
        @include text(#999, 16px, 400, 150%);
        text-decoration: line-through;
      }

      &_new {
        @include text(var(--white), 20px, 600, 120%);
      }
    }

    &_price {
      @include text(var(--white), 20px, 600, 120%);
    }

    &_buttons {
      display: none;
      gap: 16px;

      &_cart {
        padding: 8px;
        min-width: 48px;
        min-height: 48px;
        @include flex_center;
        border: 1px solid var(--white);
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }
}
