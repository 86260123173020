@import "src/utils/reset";

.item {
  width: 100%;
  padding: 8px 24px;
  border-radius: 4px;
  background: var(--background-light);
  cursor: pointer;

  &_user {
    padding: 16px;
    display: flex;
    gap: 24px;

    &_photo {
      min-width: 48px;
      max-width: 48px;
      min-height: 48px;
      max-height: 48px;
      border-radius: 4px;
      border: 3px solid var(--white);
    }

    &_info {
      display: flex;
      flex-direction: column;

      &_name {
        @include text(var(--white), 16px, 600, 150%);
      }

      &_rank {
        @include text(var(--white), 14px, 500, 171.429%);
      }
    }
  }
}
