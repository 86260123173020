@import "../../utils/reset";

.button {
  padding: 12px 40px;
  border-radius: 4px;
  border: 1px solid var(--white);
  @include text(var(--white), 16px, 600, 150%);
  text-align: center;
  transition: 0.3s;
  user-select: none;

  &:hover {
    background: var(--white);
    color: var(--background-light);
    transition: 0.3s;
  }
}
