@import "src/utils/reset";

.user {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  &_button {
    &.isUser {
      & > img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }
  }

  &_info {
    color: var(--white);
    font-size: 14px;
    font-weight: 500;
    line-height: 114.286%;
  }
}
