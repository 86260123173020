@import "src/utils/reset";

.label {
  width: 100%;
  @include label;

  &_input {
    @include input;
    min-height: 42px;

    &.error {
      border: 1px solid var(--error);
      transition: 0.3s;
    }
  }

  &_error {
    @include text(var(--error), 14px, 400, 171.429%);
  }
}

.expandedAccordion {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid var(--border);
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  &_input {
    padding: 8px 12px;
    border-radius: 4px;
    border: 1px solid var(--border);
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    resize: none;
    display: flex;
    gap: 8px;
    transition: 0.3s;

    input {
      width: 100%;
      @include text(var(--white), 14px, 400, 171.429%);

      &::placeholder {
        @include text(var(--white), 14px, 400, 171.429%);
      }
    }
  }

  &_title {
    padding: 8px 0 4px;
    @include text(var(--white), 16px, 600, 171.429%);
  }

  &_list {
    width: 100%;
    max-height: 350px;
    overflow-y: auto;
    padding: 12px;
    border-radius: 4px;
    z-index: 10;
    background: var(--background-dark);
    transition: 0.3s;

    &_item {
      padding: 8px;
      border-radius: 4px;
      @include text(var(--white), 14px, 400, 171.429%);
      transition: 0.3s;

      &:hover {
        background: var(--background-light);
        transition: 0.3s;
      }
    }
  }
}
