@import "src/utils/reset";

.categories {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  &_title {
    @include text(var(--white), 16px, 600, 150%);
    cursor: pointer;
  }

  &_subcategories {
    padding: 0 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    &_item {
      @include text(#D9D9D9, 14px, 400, 171.429%);
      cursor: pointer;
    }
  }
}
