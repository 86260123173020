@import "src/utils/reset";

.userInfo {
  padding: 32px 24px;
  border-radius: 4px;
  background: var(--background-light);

  @media screen and (max-width: 768px) {
    padding: 24px 16px;
  }

  &_basic {
    display: flex;
    gap: 16px;

    & > img {
      width: 50px;
      height: 50px;
      border-radius: 4px;
    }

    &_content {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 4px;

      &_fullname {
        @include text(var(--white), 18px, 500, 133.333%);
      }

      &_id {
        @include text(var(--white), 16px, 400, 150%);
      }
    }
  }

  button {
    //background: transparent;
    display: flex;
    align-items: flex-start;
  }

  &_divider {
    border-bottom: 1px solid var(--border);
    margin: 16px 0;
  }

  &_details {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &_item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;

      &_title {
        @include text(var(--white), 14px, 400, 171.429%);
      }

      &_data {
        @include text(var(--white), 14px, 600, 171.429%);
      }
    }
  }
}
