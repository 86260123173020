@import "src/utils/reset";

.modal {
  position: relative;
  max-width: 1100px;
  width: 70vw;
  border-radius: 4px;
  border: 1px solid var(--border);
  background: var(--background-light);
  box-shadow: 0 2px 4px -2px rgba(255, 255, 255, 0.06), 0 4px 8px -2px rgba(255, 255, 255, 0.04);
  padding: 48px;
  @include flex_center;
  flex-direction: column;
  gap: 32px;

  @media screen and (425px <= width <= 768px) {
    padding: 24px 16px;
    width: 80vw;
  }

  @media screen and (max-width: 425px) {
    padding: 24px 16px;
    width: 90vw;
    max-height: 80vh;
  }

  &_close {
    position: absolute;
    top: 24px;
    right: 16px;
    cursor: pointer;
  }

  &_heart {
    width: 48px;
    height: 48px;
    margin-bottom: 16px;

    @media screen and (max-width: 768px) {
      margin: 0;
    }
  }

  &_title {
    @include text(var(--white), 30px, 600, 133.333%);
    text-align: center;

    @media screen and (max-width: 768px) {
      font-size: 20px;
    }
  }
}
