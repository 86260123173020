@import "src/utils/reset";

.referralStructureTreePage {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 48px;

  &_dropdown {
    min-width: 200px;
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;

    span {
      @include text(var(--white), 16px, 400, 133.33%);
    }

    &_list {
      display: none;
      z-index: 10;
      min-width: 100px;
      max-height: 200px;
      width: 100%;
      overflow: auto;
      position: absolute;
      top: 28px;
      border-radius: 4px;
      border: 1px solid var(--border);
      background: rgba(15, 15, 15, 1);

      &.open {
        display: flex;
        flex-direction: column;
      }

      &_item {
        padding: 8px 12px;
        border-top: 1px solid var(--border);
        @include text(var(--white), 14px, 400, 171.429%);
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: 0.3s;

        &:first-child {
          border-top: none;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }

        &:hover {
          background: rgba(29, 29, 29, 0.95);
          transition: 0.3s;
        }
      }
    }
  }

  &_structure {
    width: 100%;
    height: 100%;
  }
}
