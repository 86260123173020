@import "src/utils/reset";

.referralsPage {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 48px;

  &_title {
    display: flex;
    align-items: center;
    gap: 16px;

    img {
      display: flex;

      @media screen and (min-width: 768px) {
        display: none;
      }
    }

    h1 {
      @include block_title;
    }
  }
}
