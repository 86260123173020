@import "src/utils/reset";

.modal {
  width: 500px;
  max-height: 80vh;
  overflow-y: auto;
  padding: 24px 16px;
  border-radius: 4px;
  border: 1px solid var(--border);
  background: var(--background-light);
  box-shadow: 0 2px 4px -2px rgba(255, 255, 255, 0.06), 0 4px 8px -2px rgba(255, 255, 255, 0.04);
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (max-width: 768px) {
    min-width: 0;
    width: 90vw;
  }

  &_button {
    padding: 8px;
    border-radius: 4px;
    background: var(--main-red);
    @include text(var(--white), 16px, 400, 150%);
  }

  &_form {
    display: flex;
    flex-direction: column;
    gap: 16px;

    label {
      width: 100%;
      @include label;

      input {
        @include input;

        &.error {
          border: 1px solid var(--error);
          transition: 0.3s;
        }
      }

      span {
        @include text(var(--error), 14px, 400, 171.429%);
      }
    }
  }

  &_buttons {
    display: flex;
    gap: 16px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
}
