@import "src/utils/reset";

.wrapper {
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: 768px) {
    display: none;
  }

  .dropdown_wrapper {
    z-index: 10;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 4px;

    &_button {
      @include text(var(--white), 14px, 500, 142.857%);
      display: flex;
      align-items: center;
      gap: 4px;

      span {
        @include text(#D9D9D9, 14px, 500, 142.857%);
        margin-right: 8px;
      }

      & > img {
        cursor: pointer;
        transform: rotate(-90deg);
      }
    }

    &_options {
      width: 100%;
      max-height: 225px;
      overflow: auto;
      position: absolute;
      top: 45px;
      border-radius: 4px;
      background: var(--background-light);

      & > li {
        padding: 8px 12px;
        @include text(var(--white), 14px, 400, 142.857%);
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: 0.3s;

        &:first-child {
          border-top: none;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }

        &:hover {
          background: #282828;
          transition: 0.3s;
        }
      }
    }
  }
}
