@import "src/utils/reset";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &_label {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    @include text(var(--white), 16px, 500, 150%);

    &_input {
      width: 100%;
      padding: 8px 12px;
      border-radius: 4px;
      border: 1px solid var(--border);
      @include text(var(--white), 16px, 400, 150%);
      transition: 0.3s;

      &.error {
        border: 1px solid var(--error);
        transition: 0.3s;
      }

      &::placeholder {
        @include text(var(--white), 16px, 400, 150%);
      }
    }
  }

  &_error {
    @include text(var(--error), 14px, 400, 171.429%);
  }
}
