@import "src/utils/reset";

// ------------------------------- desktop -------------------------------
.footerDesktop {
  padding: 64px 64px 48px;
  width: 100%;
  background: linear-gradient(-83deg, var(--background-light) 50%, var(--main-red) 50%);
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 769px) {
    display: none;
  }

  &_blocks {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 1024px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 32px 96px;
    }

    &_logo {
      width: 200px;
      height: 50px;
      cursor: pointer;
    }

    &_listWrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;

      &_subtitle {
        @include text(var(--white), 14px, 700, 142.857%);
      }

      &_lists {
        display: flex;
        flex-direction: column;
        gap: 12px;

        &_item {
          @include text(var(--white), 16px, 400, 150%);
          cursor: pointer;
        }
      }
    }
  }

  &_divider {
    width: 100%;
    height: 1px;
    background: var(--border);
    margin: 64px 0 32px;
  }

  &_bottom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_rights {
      @include text(var(--white), 16px, 400, 150%);
    }

    &_social {
      display: flex;
      gap: 24px;
    }
  }
}

// ------------------------------- mobile -------------------------------

.footerMobile {
  padding: 64px 64px 48px;
  width: 100%;
  background: linear-gradient(-87deg, var(--background-light) 50%, var(--main-red) 50%);
  display: none;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  @media screen and (max-width: 769px) {
    display: flex;
  }

  @media screen and (max-width: 425px) {
    padding: 32px 16px;
  }

  &_logo {
    height: 40px;
    justify-content: flex-start;
  }

  &_blocks {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 1024px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 32px 64px;
    }

    &_listWrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;

      &_subtitle {
        @include text(var(--white), 14px, 700, 142.857%);
      }

      &_lists {
        display: flex;
        flex-direction: column;
        gap: 12px;

        &_item {
          @include text(var(--white), 16px, 400, 150%);
        }
      }
    }
  }

  &_divider {
    margin: 24px 0 8px;
    width: 100%;
    height: 1px;
    background: var(--border);
  }

  &_rights {
    @include text(var(--white), 16px, 400, 150%);
  }
}
