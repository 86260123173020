@import "src/utils/reset";

.wrapper {
  @include page_box;

  &_searchResultsPage {
    @include container_max_width;
    display: flex;
    flex-direction: column;
    gap: 48px;

    &_title {
      display: flex;
      flex-direction: column;
      gap: 16px;

      &_main {
        @include main_title;
      }

      &_description {
        @include text(#D9D9D9, 18px, 400, 24px);
      }
    }

    &_ordering {
      display: flex;
      flex-direction: column;
      gap: 48px;

      &_breadcrumbs {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 64px;
      }

      &_content {
        &_products {
          display: flex;
          align-items: flex-start;
          gap: 32px;

          &_sidebar {
            min-width: 300px;
            max-width: 300px;
            display: flex;
            flex-direction: column;
            gap: 16px;

            @media screen and (max-width: 1024px) {
              display: none;
            }
          }

          &_list {
            width: 100%;
            height: 100%;

            &_pagination {
              display: flex;
              flex-direction: column;
              gap: 48px;

              .products {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 32px 32px;

                @media screen and (max-width: 1280px) {
                  grid-template-columns: repeat(2, 1fr);
                }

                @media screen and (max-width: 500px) {
                  grid-template-columns: repeat(1, 1fr);
                }
              }
            }
          }
        }
      }
    }

  }
}
