@import "src/utils/reset";

.breadcrumbs {
  max-width: 768px;
  overflow-x: auto;
  scrollbar-width: none;
  display: flex;
  align-items: center;

  @media screen and (768px <= width <= 1280px) {
    max-width: calc(100vw - (300px + 32px + 128px));
  }

  @media screen and (max-width: 768px) {
    max-width: calc(100vw - 64px);
  }

  @media screen and (max-width: 425px) {
    max-width: calc(100vw - 32px);
  }

  &_divider {
    @include text(var(--white), 14px, 500, 142.857%);
    padding: 0 16px;
  }

  &_path {
    @include text(var(--white), 14px, 500, 142.857%);
    white-space: nowrap;
    user-select: none;
  }
}
