@import "src/utils/reset";

.withdrawalRequestPage {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 48px;

  &_title {
    display: flex;
    align-items: flex-start;
    gap: 16px;

    h1 {
      @include block_title;
    }

    img {
      display: flex;

      @media screen and (min-width: 768px) {
        display: none;
      }
    }
  }

  &_flex {
    display: flex;
    align-items: flex-start;
    gap: 32px;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }
  }
}
