@import "src/utils/reset";

.modal {
  width: 70vw;
  height: 80vh;
  border-radius: 16px;
  border: 1px solid var(--border);
  background: var(--background-light);
  box-shadow: 0 2px 4px -2px rgba(16, 24, 40, 0.06), 0 4px 8px -2px rgba(16, 24, 40, 0.04);

  @media screen and (max-width: 768px) {
    width: 90vw;
  }

  &_content {
    padding: 24px;
    width: 100%;
    max-height: 100%;
    border-radius: 16px 16px 0 0;
    overflow-y: auto;

    @media screen and (max-width: 425px) {
      padding: 12px;
    }

    .subtitle {
      @include text(var(--white), 18px, 600, 133.33%);
    }

    .description {
      @include text(var(--white), 16px, 500, 150%);
    }

    &_titleContainer {
      padding: 0 0 16px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      border-bottom: 1px solid var(--border);

      @media screen and (max-width: 425px) {
        padding: 0 12px 12px;
      }

      & > h3 {
        @include text(var(--white), 24px, 600, 145.455%);
      }

      & > div > p {
        @include text(var(--border), 16px, 400, 150%);
      }
    }

    &_block {
      padding: 16px 0;
      display: flex;
      flex-direction: column;
      gap: 8px;

      @media screen and (max-width: 425px) {
        padding: 12px;
      }
    }

    &_agreement {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 16px;
    }
  }
}
