@import "src/utils/reset";

.wrapper {
  @include page_box;
}

.certificatesPage {
  @include container_max_width;
  display: flex;
  flex-direction: column;
  gap: 48px;

  & > h1 {
    @include main_title;
  }

  &_certificates {
    display: flex;
    flex-direction: column;
    gap: 48px;
  }
}
