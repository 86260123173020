@import "src/utils/reset";

.label {
  width: 100%;
  @include label;

  .input {
    @include input;

    &.error {
      border: 1px solid var(--error);
      transition: 0.3s;
    }
  }

  .errorText {
    @include text(var(--error), 14px, 400, 171.429%);
  }
}
