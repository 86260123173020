@import "src/utils/reset";

.description {
  div {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  h2 {
    @include text(#FFF, 30px, 400, 133.33%);
    font-family: 'NAMU', sans-serif;
    margin-bottom: 16px;
  }

  p {
    @include text(#FFF, 16px, 400, 150%);
    margin-bottom: 32px;
  }

  ul {
    padding-left: 19px;
    display: flex;
    flex-direction: column;

    li {
      position: relative;
      color: var(--white);
      font-size: 16px;
      line-height: 150%;

      &:before {
        content: '';
        position: absolute;
        width: 3px;
        height: 3px;
        background: var(--white);
        top: 50%;
        left: -11px;
        border-radius: 50%;
        transform: translateY(-50%);
      }
    }
  }

  //display: flex;
  //flex-direction: column;
  //gap: 16px;
  //
  //&_title {
  //  color: var(--white);
  //  font-family: 'NAMU', sans-serif;
  //  font-size: 30px;
  //  line-height: 133.333%;
  //}
  //
  //&_text {
  //  color: var(--white);
  //  font-size: 16px;
  //  line-height: 150%;
  //}
}
