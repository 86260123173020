@import "../../utils/reset";

.loginForm {
  min-width: 500px;
  padding: 32px 24px;
  border-radius: 4px;
  border: 1px solid var(--border);
  background: var(--background-light);
  box-shadow: 0 2px 4px -2px rgba(255, 255, 255, 0.06), 0 4px 8px -2px rgba(255, 255, 255, 0.04);

  &.modal {
    z-index: 10;
    position: absolute;
    top: 115px;
    right: 64px;
  }

  @media screen and (max-width: 768px) {
    min-width: 0;
    width: 100%;
  }

  @media screen and (max-width: 425px) {
    min-width: 0;
    padding: 32px 16px;
  }

  &_form {
    width: 100%;
    @include flex_center;
    flex-direction: column;
    gap: 24px;

    &_flexEnd {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 8px;

      &_inputs {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      &_forgotPassword {
        @include text(var(--main-green), 14px, 600, 171.429%);
        cursor: pointer;
      }
    }

    &_registration {
      @include text(var(--white), 14px, 400, 142.857%);
      text-align: center;

      &_link {
        @include text(var(--main-green), 14px, 700, 142.857%);
      }
    }
  }
}
