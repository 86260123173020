@import "src/utils/reset";

.wrapper {
  @include page_box;

  &_productPage {
    @include container_max_width;
    display: flex;
    flex-direction: column;
    gap: 48px;

    &_description {
      width: calc(100% - (415px + 32px));
      display: flex;
      flex-direction: column;
      gap: 32px;
      padding-bottom: 48px;

      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }
  }
}
