@import "src/utils/reset";

// -------------------------------------------- desktop --------------------------------------------
.desktop {
  width: 100%;
  position: relative;

  @media screen and (max-width: 768px) {
    display: none;
  }

  &_backgroundImage {
    width: 100%;
  }

  &_content {
    max-width: 500px;
    position: absolute;
    right: 6.5vw;
    left: 55%;
    top: 40%;
    @include flex_center;
    flex-direction: column;
    gap: 32px;

    @media screen and (max-width: 1024px) {
      top: 35%;
      right: 32px;
      gap: 16px;
    }

    &_description {
      @include text(var(--white), 16px, 400, 19.5px);

      @media screen and (max-width: 1024px) {
        font-size: 14px;
      }
    }
  }
}

// -------------------------------------------- mobile --------------------------------------------

.mobile {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media screen and (min-width: 769px) {
    display: none;
  }

  &_backgroundImage {
    width: 100%;
  }

  &_description {
    position: absolute;
    left: 55%;
    top: 25%;
    right: 3vw;
    @include text(var(--white), 14px, 400, 19.5px);

    @media screen and (max-width: 375px) {
      font-size: 12px;
    }
  }
}
