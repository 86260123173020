@import "src/utils/reset";

.sidebar {
  min-width: 300px;
  min-height: 100vh;
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  background: var(--background-light);

  @media screen and (max-width: 768px) {
    display: none;
  }

  &_links {
    display: flex;
    flex-direction: column;

    &_item {
      padding: 8px;
      border-radius: 4px;
      transition: 0.3s;
      @include text(var(--white), 16px, 500, 150%);
      cursor: pointer;

      &.active {
        background: var(--main-red);
        transition: 0.3s;
      }
    }
  }

  &_logout {
    padding: 0 8px;
    @include text(#999, 16px, 500, 150%);
    cursor: pointer;
  }
}
