@import "src/utils/reset";

.emptyState {
  min-width: 100%;
  min-height: 100%;
  @include flex_center;
  flex-direction: column;
  gap: 24px;

  &_text {
    @include text(var(--white), 30px, 600, 133.333%);
    text-align: center;

    @media screen and (max-width: 425px) {
      font-size: 24px;
    }
  }
}
