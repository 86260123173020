@import "src/utils/reset";

.withdrawalPoints {
  width: 100%;
  padding: 32px 24px;
  border-radius: 4px;
  background: var(--background-light);

  @media screen and (max-width: 768px) {
    padding: 24px 16px;
  }

  &_block {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    &_subtitle {
      @include text(var(--white), 14px, 400, 171.429%);
    }

    &_count {
      @include text(var(--white), 14px, 500, 171.429%);
    }
  }

  .title {
    @include text(var(--white), 18px, 500, 133.333%);
  }

  &_divider {
    width: 100%;
    height: 1px;
    background: var(--border);
    margin: 16px 0 24px;
  }

  &_points {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 24px;
  }
}
