@import "src/utils/reset";
@import "src/utils/mixins";

.modal {
  width: 500px;
  max-height: 80vh;
  overflow-y: auto;
  padding: 24px 16px;
  border-radius: 4px;
  border: 1px solid var(--border);
  background: var(--background-light);
  box-shadow: 0 2px 4px -2px rgba(255, 255, 255, 0.06), 0 4px 8px -2px rgba(255, 255, 255, 0.04);
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (max-width: 768px) {
    width: 90vw;
  }

  @media screen and (max-width: 425px) {
    overflow-y: auto;
  }

  &_title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > h3 {
      color: var(--white);
      font-size: 18px;
      font-weight: 500;
      line-height: 155.556%;
    }

    & > img {
      cursor: pointer;
    }
  }

  &_form {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &_photoContainer {
      padding: 12px 24px;
      display: flex;
      align-items: center;
      gap: 16px;

      @media screen and (max-width: 768px) {
        padding: 12px 0;
      }

      @media screen and (max-width: 425px) {
        flex-direction: column;
      }

      &_preview {
        cursor: pointer;
        width: 64px;
        height: 64px;
        border-radius: 4px;
      }

      &_add {
        padding: 4px 8px;
        width: 100%;
        border-radius: 8px;
        border: 1px solid var(--border);
        @include flex_center;
        flex-direction: column;
        gap: 4px;
        cursor: pointer;
        transition: 0.3s;

        &:hover {
          transform: scale(0.95);
          transition: 0.3s;
        }

        & > img {
          width: 16px;
          height: 16px;
        }

        & > p {
          @include text(var(--white), 14px, 500, 171.429%);
          text-align: center;
        }
      }
    }

    &_buttons {
      width: 100%;
      padding-top: 16px;
      display: flex;
      gap: 16px;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
    }
  }
}
