@import "src/utils/reset";

.wrapper {
  @include page_box;

  &_contacts {
    @include container_max_width;
    display: flex;
    flex-direction: column;
    gap: 48px;
    flex: 1;

    &_title {
      @include main_title;
    }

    &_content {
      width: 100%;
      display: flex;

      @media screen and (max-width: 1024px) {
        flex-direction: column;
      }

      &_info {
        padding: 48px;
        width: 35%;
        background: var(--main-green);
        display: flex;
        flex-direction: column;
        gap: 32px;
        border-radius: 4px 0 0 4px;

        @media screen and (max-width: 1024px) {
          width: 100%;
          border-radius: 4px 4px 0 0;
        }

        @media screen and (425px <= width <= 768px) {
          padding: 48px 32px;
        }

        @media screen and (max-width: 425px) {
          padding: 48px 16px;
        }

        &_block {
          display: flex;
          align-items: flex-start;
          gap: 16px;

          & > img {
            padding-top: 4px;
          }

          &_description {
            display: flex;
            flex-direction: column;
            gap: 16px;

            &_title {
              @include text(var(--white), 20px, 500, 150%);
            }

            &_data {
              @include text(var(--white), 16px, 500, 150%);
            }
          }
        }
      }

      &_form {
        padding: 96px 64px;
        background: var(--background-light);
        display: flex;
        flex-direction: column;
        gap: 48px;
        flex: 1;
        border-radius: 0 4px 4px 0;

        @media screen and (max-width: 1024px) {
          border-radius: 0 0 4px 4px;
        }

        @media screen and (425px <= width <= 768px) {
          padding: 48px 32px;
          gap: 32px;
        }

        @media screen and (max-width: 425px) {
          padding: 24px 16px;
          gap: 32px;
        }

        &_subtitle {
          @include text(var(--white), 36px, 600, 122.222%);
          letter-spacing: -0.72px;

          @media screen and (max-width: 768px) {
            @include text(var(--white), 24px, 600, 133.333%);
            letter-spacing: -0.48px;
          }
        }

        &_inputs {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 24px;

          &_flex2 {
            display: flex;
            gap: 16px;

            @media screen and (max-width: 1024px) {
              flex-direction: column;
            }
          }

          &_message {
            display: flex;
            flex-direction: column;
            gap: 8px;
            @include text(var(--white), 16px, 500, 150%);

            .error {
              @include text(var(--error), 14px, 400, 171.429%);
            }
          }
        }
      }
    }
  }
}
