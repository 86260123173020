@import "src/utils/reset";

.container {
  padding: 24px;
  background: var(--main-red);
  border-radius: 4px;

  &_condition {
    @include text(var(--white), 16px, 500, 150%);

    @media screen and (max-width: 425px) {
      font-size: 14px;
    }

    & > span {
      cursor: pointer;
      font-weight: 700;
    }
  }
}
