@import "src/utils/reset";

.wrapper {
  @include page_box;

  &_registrationPage {
    @include container_max_width;
    display: flex;
    flex-direction: column;
    gap: 48px;

    &_title {
      @include main_title;
    }

    &_content {
      display: flex;
      border-radius: 4px;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }

      &_rectangle {
        width: 23%;
        min-height: 100%;
        background: var(--main-green);
        border-radius: 4px 0 0 4px;

        @media screen and (max-width: 768px) {
          width: 100%;
          min-height: 100px;
          border-radius: 4px;
        }
      }

      &_form {
        width: 77%;
        padding: 64px;
        display: flex;
        flex-direction: column;
        gap: 48px;
        border-radius: 0 4px 4px 0;
        background: var(--background-light);

        @media screen and (768px <= width <= 1024px) {
          padding: 32px;
        }

        @media screen and (max-width: 768px) {
          width: 100%;
          padding: 32px 16px;
          gap: 24px;
        }

        &_top {
          display: flex;
          align-items: center;
          justify-content: space-between;

          @media screen and (max-width: 768px) {
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
          }

          & > h2 {
            @include text(var(--white), 30px, 400, 133.333%);
          }

          & > p {
            @include text(var(--white), 14px, 400, 142.857%);

            & > span {
              cursor: pointer;
              color: var(--main-green);
              font-weight: 600;
            }
          }
        }

        .subtitle {
          @include text(var(--white), 18px, 600, 133.333%);
        }

        &_block {
          display: flex;
          flex-direction: column;
          gap: 24px;

          &_user {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 16px;

            &_flex2 {
              width: 100%;
              display: flex;
              gap: 16px;

              @media screen and (max-width: 768px) {
                flex-direction: column;
              }

              .label {
                width: 100%;
                @include label;
              }
            }

            &_invite {
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              gap: 24px;

              @media screen and (max-width: 425px) {
                gap: 8px;
              }

              & > p {
                @include text(var(--white), 16px, 500, 150%);
              }

              &_checkboxes {
                display: flex;
                align-items: center;
                gap: 16px;
              }
            }
          }
        }

        &_buttons {
          width: 100%;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          gap: 16px;

          @media screen and (max-width: 768px) {
            flex-direction: column-reverse;
            gap: 24px;
          }
        }
      }
    }
  }
}
