@import "src/utils/reset";

.filters {
  z-index: 10;
  position: relative;

  @media screen and (max-width: 425px) {
    width: 100%;
  }

  &_text {
    @include text(var(--white), 16px, 500, 150%);
  }

  &_button {
    display: flex;
    align-items: center;
    gap: 8px;

    @media screen and (max-width: 425px) {
      width: 100%;
      justify-content: center;
      padding: 12px 40px;
      border-radius: 4px;
      border: 1px solid var(--white);
    }
  }

  &_open {
    position: absolute;
    left: 0;
    top: 40px;
    width: 350px;
    padding: 16px;
    border-radius: 4px;
    border: 1px solid var(--border);
    background: var(--background-light);
    box-shadow: 0 2px 4px -2px rgba(255, 255, 255, 0.06), 0px 4px 8px -2px rgba(255, 255, 255, 0.04);
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media screen and (max-width: 425px) {
      width: 100%;
      top: 58px;
    }

    &_block {
      display: flex;
      flex-direction: column;
      gap: 8px;

      &_dates {
        display: flex;
        align-items: center;
        gap: 16px;
      }
    }
  }
}
