@import "src/utils/reset";
@import "src/utils/mixins";

.reportStatistics {
  width: 100%;
  //min-width: 975px;
  max-width: 975px;
  overflow-x: auto;
  padding: 16px;
  border-radius: 4px;
  background: var(--background-light);
  margin: 0 auto;

  @media screen and (min-width: 1440px) {
    max-width: none;
  }

  &_titles {
    display: flex;

    &_item {
      min-width: 190px;
      display: flex;
      flex-direction: column;
      gap: 16px;

      &_subtitle {
        @include text(#D9D9D9, 14px, 400, 171.429%);
      }

      &_data {
        @include text(var(--white), 14px, 500, 171.429%);
      }
    }

    &_divider {
      margin: 0 16px;
      min-width: 1px;
      min-height: 64px;
      height: 100%;
      background: var(--border);
    }
  }
}
