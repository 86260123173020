@import "src/utils/reset";

.wrapper {
  padding: 48px 0;
  min-width: 100%;
  min-height: 100vh;
  background: var(--background-dark);
  flex: 1;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 425px) {
    padding: 32px 0;
  }

  .aboutUsPage {
    @include container_max_width;
    display: flex;
    flex-direction: column;
    gap: 96px;

    @media screen and (max-width: 768px) {
      gap: 48px;
    }

    &_titles {
      position: relative;
      padding: 0 64px;
      display: flex;
      flex-direction: column;
      gap: 72px;

      @media screen and (max-width: 768px) {
        padding: 0 32px;
      }

      @media screen and (max-width: 425px) {
        padding: 0 16px;
        gap: 56px;
      }

      &_main {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        & > h1 {
          @include text(rgba(255, 255, 255, 0.50), 72px, 400, 133.33%);
          font-family: "NAMU", sans-serif;

          @media screen and (max-width: 768px) {
            font-size: 40px;
          }

          @media screen and (max-width: 425px) {
            font-size: 24px;
          }

          span {
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: rgba(255, 255, 255, 0.50);
            -webkit-text-fill-color: transparent;
            @include text(rgba(255, 255, 255, 0.50), 72px, 400, 133.33%);
            font-family: "NAMU", sans-serif;

            @media screen and (max-width: 768px) {
              font-size: 40px;
            }

            @media screen and (max-width: 425px) {
              font-size: 24px;
            }
          }
        }

        & > p {
          @include text(var(--white), 24px, 400, 133.33%);
          font-family: "NAMU", sans-serif;
          letter-spacing: 5px;

          @media screen and (max-width: 768px) {
            font-size: 18px;
          }

          @media screen and (max-width: 425px) {
            font-size: 12px;
          }
        }
      }

      &_minor {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 32px;

        @media screen and (max-width: 768px) {
          gap: 16px;
        }

        & > h2 {
          max-width: 38.5vw;
          @include text(var(--white), 56px, 400, 142.857%);
          font-family: "NAMU", sans-serif;

          @media screen and (max-width: 1024px) {
            font-size: 40px;
          }

          @media screen and (max-width: 768px) {
            font-size: 24px;
          }

          @media screen and (max-width: 425px) {
            max-width: none;
            font-size: 18px;
          }

          & > span {
            font-family: "NAMU", sans-serif;
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: var(--white);
            -webkit-text-fill-color: transparent;
            @include text(var(--white), 56px, 400, 142.857%);

            @media screen and (max-width: 1024px) {
              font-size: 40px;
            }

            @media screen and (max-width: 768px) {
              font-size: 24px;
            }

            @media screen and (max-width: 425px) {
              font-size: 18px;
            }
          }
        }

        & > p {
          @include text(var(--main-red), 56px, 400, 142.867%);
          white-space: nowrap;

          @media screen and (max-width: 1024px) {
            font-size: 40px;
          }

          @media screen and (max-width: 768px) {
            font-size: 24px;
          }

          @media screen and (max-width: 425px) {
            font-size: 18px;
          }
        }
      }

      &_circular {
        position: absolute;
        bottom: 0;
        left: 35%;

        @media screen and (max-width: 1024px) {
          display: none;
        }
      }
    }

    &_block {
      padding: 0 64px;
      display: flex;
      flex-direction: column;
      gap: 56px;

      @media screen and (max-width: 768px) {
        padding: 0 32px;
        gap: 32px;
      }

      @media screen and (max-width: 425px) {
        padding: 0 16px;
      }

      &.fullWidth {
        padding: 0;

        & > div:nth-child(1) {
          padding: 0 64px;

          @media screen and (425px <= width <= 768px) {
            padding: 0 32px;
          }

          @media screen and (max-width: 425px) {
            padding: 0 16px;
          }
        }
      }

      & > div:nth-child(1) {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 56px;
        flex-wrap: wrap;

        @media screen and (max-width: 768px) {

        }
      }

      h2 {
        @include block_title;
      }

      .text {
        max-width: 640px;
        @include text(var(--white), 16px, 400, 150%);

        @media screen and (max-width: 768px) {
          @include text(var(--white), 14px, 400, 171.429%);
        }
      }

      &_companyValues {
        display: flex;
        gap: 32px;

        @media screen and (max-width: 768px) {
          flex-direction: column;
          gap: 24px;
        }
      }
    }
  }
}
