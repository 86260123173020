@import "src/utils/reset";

.wrapper {
  @include page_box;

  &_successStoryPage {
    @include container_max_width;
    display: flex;
    flex-direction: column;
    gap: 96px;

    h1 {
      @include main_title;
    }

    &_storiesBlock {
      display: flex;
      flex-direction: column;
      gap: 56px;

      h3 {
        @include block_title;
      }

      &_items {
        display: flex;
        gap: 32px;

        @media screen and (max-width: 425px) {
          flex-direction: column;
        }
      }
    }
  }
}
