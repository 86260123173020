@import "../../utils/reset";

.radioButton {
  display: flex;
  align-items: center;
  gap: 8px;

  &_circle {
    width: 16px;
    height: 16px;
    background: var(--white);
    border: 1px solid var(--border);
    border-radius: 50%;
    transition: 0.3s;
    @include flex_center;

    &.checked {
      border: 1px solid var(--main-green);
      transition: 0.3s;
    }

    &_checked {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: var(--main-green);
    }
  }

  &_label {
    @include text(var(--white), 14px, 400, 171.429%);
  }
}
