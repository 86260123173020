@mixin flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin page_box {
  min-width: 100%;
  min-height: 100vh;
  padding: 48px 64px;
  background: var(--background-dark);
  flex: 1;
  display: flex;
  justify-content: center;

  @media screen and (768px <= width <= 1280px) {
    padding: 48px;
  }

  @media screen and (425px <= width <= 768px) {
    padding: 48px 32px;
  }

  @media screen and (max-width: 425px) {
    padding: 32px 16px;
  }
}

@mixin container_max_width {
  width: 100%;
  height: 100%;
  max-width: 1440px;
}

@mixin main_title {
  @include text(var(--white), 72px, 400, 133.333%);
  font-family: NAMU, 'sans-serif';
  text-transform: uppercase;

  & > span {
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: var(--white);
    -webkit-text-fill-color: transparent;
  }

  @media screen and (768px <= width <= 1280px) {
    font-size: 56px;
  }

  @media screen and (425px <= width <= 768px) {
    font-size: 40px;
  }

  @media screen and (max-width: 425px) {
    font-size: 24px;
  }
}

@mixin block_title {
  font-family: 'NAMU', sans-serif;
  @include text(var(--white), 40px, 400, 140%);
  text-transform: uppercase;

  & > span {
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: var(--white);
    -webkit-text-fill-color: transparent;
    @include text(var(--white), 40px, 400, 140%);
  }

  @media screen and (max-width: 768px) {
    font-size: 24px;

    & > span {
      font-size: 24px;
    }
  }
}

@mixin subtitle {
  @include text(var(--white), 16px, 500, 150%);
}

@mixin text($color, $font-size, $font-weight, $line-height) {
  color: $color;
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $line-height;
}

@mixin input {
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid var(--border);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  resize: none;
  @include text(var(--white), 14px, 400, 171.429%);
  transition: 0.3s;

  &::placeholder {
    @include text(var(--white), 14px, 400, 171.429%);
  }
}

@mixin label {
  display: flex;
  flex-direction: column;
  gap: 8px;
  @include text(var(--white), 16px, 500, 171.429%);
}
