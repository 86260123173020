@import "src/utils/reset";

.backgroundOverlay {
  @include flex_center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 4px;

  &_content {
    margin: auto;
    display: block;
    width: 300px;
    height: 300px;
    max-width: 350px;
    max-height: 350px;
    border-radius: 50%;
    border: 1px solid var(--border);

    @media screen and (max-width: 425px) {
      width: 250px;
      height: 250px;
    }
  }

  .close {
    position: absolute;
    top: 24px;
    right: 16px;
    cursor: pointer;
  }
}
