@import "src/utils/reset";

// --------------------------- desktop -----------------------------
.photos_desktop {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 32px;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    display: none;
  }

  img {
    border-radius: 4px;
  }

  &_block {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
}

// --------------------------- mobile -----------------------------
.photos_mobile {
  max-height: 400px;
  display: none;
  justify-content: center;
  gap: 16px;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    display: flex;
  }

  &_block {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

