@import "src/utils/mixins";

// navigation buttons
.button {
  width: 48px;
  height: 48px;
  border: 1px solid var(--white);
  border-radius: 4px;
  cursor: pointer;
  z-index: 10;

  &.disabled {
    opacity: 0.5;
  }

  &.leftBtn {
    position: absolute;
    top: 40%;
    transform: translate(-40%, 40%);
    left: -48px;
    cursor: pointer;

    background-size: cover;
    background-image: url('../../assets/chevron-left.svg');
    transition: 0.3s;

    &:hover {
      background: rgba(255, 255, 255, 0.60) url('../../assets/chevron-left-hover.svg');
      background-size: contain;
      transition: 0.3s;
    }
  }

  &.rightBtn {
    position: absolute;
    top: 40%;
    transform: translate(-40%, 40%);
    right: -86px;
    cursor: pointer;

    background-size: cover;
    background-image: url('../../assets/chevron-right.svg');
    transition: 0.3s;

    &:hover {
      background: rgba(255, 255, 255, 0.60) url('../../assets/chevron-right-hover.svg');
      background-size: contain;
      transition: 0.3s;
    }
  }

  @media screen and (max-width: 1280px) {
    display: none;
  }
}

// styles for page
.wrapper {
  @include page_box;

  .marketingPlanPage {
    @include container_max_width;
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 96px;

    @media screen and (max-width: 768px) {
      gap: 48px;
    }

    &_title {
      @include main_title;
    }

    &_content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 48px;

      &_plan {
        max-width: 1140px;
        width: 100%;
      }
    }
  }
}
