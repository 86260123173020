@import "src/utils/reset";

.personalOrdersPage {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 48px;

  &_title {
    display: flex;
    align-items: center;
    gap: 16px;

    img {
      display: flex;

      @media screen and (min-width: 768px) {
        display: none;
      }
    }

    h1 {
      @include block_title;
    }
  }

  &_tools {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 425px) {
      flex-direction: column;
      gap: 16px;
    }
  }

  &_orders {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 16px;
  }
}
