@import "src/utils/reset";

.withdrawalRequestItem {
  width: 100%;
  padding: 24px;
  border-radius: 4px;
  background: var(--background-light);
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (max-width: 768px) {
    padding: 24px 16px;
  }

  .title {
    @include text(var(--white), 18px, 500, 133.333%);
  }

  &_divider {
    width: 100%;
    height: 1px;
    background: var(--border);
  }

  &_pointsCircular {
    &_points {
      padding: 8px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 16px;
      flex: 1;

      &_count {
        display: flex;
        flex-direction: column;
        gap: 8px;
        flex: 1;

        &_wrapper {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 24px;

          .subtitle {
            @include text(var(--white), 14px, 400, 171.429%);
          }

          .count {
            @include text(var(--white), 14px, 500, 171.429%);
          }
        }
      }

      &_form {
        display: flex;
        flex-direction: column;
        gap: 24px;

        &_label {
          @include label;

          & > input {
            @include input;
          }

          .error {
            @include text(var(--error), 14px, 400, 171.429%);
          }
        }
      }
    }
  }

  //&_payment {
  //  padding-top: 8px;
  //  display: flex;
  //  flex-direction: column;
  //  gap: 8px;
  //}
}
