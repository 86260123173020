@import "src/utils/reset";

.item {
  @include flex_center;
  flex-direction: column;
  gap: 24px;

  & > img {
    width: 100%;
  }

  &_description {
    @include flex_center;
    flex-direction: column;
    gap: 16px;

    & > p {
      &:nth-child(1) {
        @include text(var(--white), 14px, 500, 171.429%);
        text-transform: uppercase;
        text-align: center;
      }

      &:nth-child(2) {
        @include text(var(--white), 14px, 400, 171.429%);
        text-align: center;
      }
    }
  }
}
