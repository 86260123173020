@import "src/utils/reset";

.productItemOrder {
  padding: 8px 0;
  display: flex;
  align-items: center;
  gap: 32px;

  @media screen and (max-width: 768px) {
    align-items: flex-start;
  }

  @media screen and (max-width: 425px) {
    gap: 24px;
  }

  &_photo {
    width: 75px;
    height: 80px;
    @include flex_center;

    @media screen and (max-width: 425px) {
      width: 48px;
      height: 48px;
    }

    & > img {
      border-radius: 4px;
      max-width: 100%;
      max-height: 100%;
    }
  }

  &_description {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    @media screen and (max-width: 768px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, auto);
      gap: 16px 16px;
      grid-template-areas:
    "title title"
    "price price"
    "count delete";
    }

    &_title {
      width: 30%;
      display: flex;
      flex-direction: column;
      gap: 16px;

      @media screen and (max-width: 768px) {
        width: 100%;
        grid-area: title;
      }

      &_name {
        &_text {
          @include text(var(--white), 16px, 400, 150%);
        }

        &_code {
          @include text(#D9D9D9, 14px, 400, 171.429%);

          & > span {
            @include text(var(--white), 14px, 400, 24px);
          }
        }
      }

      &_points {
        @include text(var(--white), 14px, 400, 171.429%);
      }
    }

    &_count {
      display: flex;
      gap: 24px;
      align-items: center;

      @media screen and (max-width: 768px) {
        grid-area: count;
      }

      &_button {
        padding: 8px;
        width: 40px;
        height: 40px;
        border-radius: 4px;
        border: 1px solid var(--white);
        @include flex_center;
        text-align: center;
        font-size: 24px;
        color: var(--white);
        transition: 0.3s;

        @media screen and (max-width: 425px) {
          width: 32px;
          height: 32px;
        }

        &:hover {
          background: var(--white);
          color: var(--background-light);
          transition: 0.3s;
        }
      }

      &_number {
        color: var(--white);
        font-size: 16px;
        font-weight: 500;
        line-height: 150%;
      }
    }

    &_price {
      min-width: 150px;

      @media screen and (max-width: 768px) {
        grid-area: price;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;
        gap: 8px;
      }

      &_old {
        @include text(#999, 18px, 400, 133.333%);
        text-decoration: line-through;

        @media screen and (max-width: 425px) {
          font-size: 14px;
        }
      }

      &_new {
        @include text(var(--main-red), 24px, 600, 166.667%);

        @media screen and (max-width: 425px) {
          font-size: 16px;
        }
      }

      &_full {
        @include text(#999, 24px, 600, 133.333%);

        @media screen and (max-width: 425px) {
          font-size: 16px;
        }
      }
    }

    &_delete {
      & > img {
        cursor: pointer;
      }

      @media screen and (max-width: 768px) {
        grid-area: delete;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
