@import "mixins";
@import "rating";

* {
  padding: 0;
  margin: 0;
  border: 0;
}

*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:focus, :active {
  outline: none;
}

a:focus, a:active {
  outline: none;
}

nav, footer, header, aside {
  display: block;
}

html, body {
  height: 100%;
  width: 100%;
  font-style: 100%;
  line-height: 1;
  font-style: 14px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  background: var(--background-dark);
}

input, button, textarea {
  font-family: inherit;
  background: transparent;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a, a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

ul li {
  list-style-type: none;
}

img {
  vertical-align: top;
}

h1, h2, h3, h4, h5, h6 {
  font-style: inherit;
  font-weight: 400;
}

body {
  overflow-x: hidden;
  font-family: 'Montserrat', sans-serif;
}

progress[value] {
  /* Reset the default appearance */
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 8px;
  border-radius: 100px;
}

progress[value]::-webkit-progress-bar {
  /* Style the background (unfilled part) of the progress bar */
  background-color: var(--white);
  border-radius: 100px;
}

progress[value]::-webkit-progress-value {
  /* Style the foreground (filled part) of the progress bar */
  background-color: var(--main-green);
  border-radius: 100px;
}

textarea {
  @include input;
}

:root {
  --white: #FFF;
  --main-green: #239B85;
  --background-light: #1D1D1D;
  --background-dark: #0F0F0F;
  --main-red: #9B232C;
  --main-yellow: #FAF84B;
  --error: #E7121F;
  --border: #888;
  --hue: 223;
  --bg: hsl(var(--hue), 10%, 90%);
  --fg: hsl(var(--hue), 10%, 10%);
  --trans-dur: 0.3s;
}

