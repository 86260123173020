@import "src/utils/reset";

.event {
  background: var(--white);
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 32px;

  @media screen and (max-width: 1280px) {
    flex-direction: column;
    gap: 0;
  }

  & > img {
    width: 100%;
    height: 100%;
    border-radius: 4px 0 0 4px;

    @media screen and (max-width: 1280px) {
      border-radius: 4px 4px 0 0;
    }
  }

  &_details {
    padding: 16px 32px;
    display: flex;
    flex-direction: column;
    gap: 32px;

    &_gap8 {
      display: flex;
      flex-direction: column;
      gap: 8px;

      h3 {
        @include text(var(--background-dark), 20px, 600, 160%);
      }

      &_address {
        display: flex;
        flex-direction: column;
        gap: 4px;

        & > p {
          @include text(var(--background-dark), 16px, 500, 150%);
        }
      }

      & > p {
        @include text(var(--background-dark), 16px, 400, 150%);
      }
    }

    &_button {
      display: flex;
      gap: 8px;
      align-items: center;
      @include text(var(--main-red), 18px, 600, 133.33%);
    }
  }
}
