@import "src/utils/reset";

.wrapper {
  @include page_box;

  &_homePage {
    //position: relative;
    @include container_max_width;
    display: flex;
    flex-direction: column;
    gap: 96px;

    //&:before {
    //  content: "Обирай";
    //  position: absolute;
    //  top: 20%;
    //  @include text(var(--white), 128px, 400, 133.333%);
    //  opacity: 0.5;
    //  font-family: NAMU, 'sans-serif';
    //  text-transform: uppercase;
    //  -webkit-text-stroke-width: 1px;
    //  -webkit-text-stroke-color: var(--white);
    //  -webkit-text-fill-color: transparent;
    //  letter-spacing: 61.44px;
    //
    //  //@media screen and (768px <= width <= 1440px) {
    //  //  font-size: 56px;
    //  //}
    //
    //  @media screen and (425px <= width <= 768px) {
    //    font-size: 40px;
    //  }
    //
    //  @media screen and (max-width: 425px) {
    //    font-size: 24px;
    //  }
    //  //left: 0;
    //  //z-index: -1;
    //}

    @media screen and (max-width: 768px) {
      gap: 48px;
    }
  }
}
