@import "src/utils/reset";

.referralStructureListPage {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 48px;

  &_breadcrumbs {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 32px;
    flex-wrap: wrap-reverse;
  }

  &_content {
    display: flex;
    flex-direction: column;
    gap: 48px;

    &_referrals {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: auto;
      gap: 32px;

      @media screen and (max-width: 1280px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
        gap: 32px;
      }

      @media screen and (max-width: 1024px) {
        display: flex;
        flex-direction: column;
        gap: 32px;
      }
    }
  }
}
