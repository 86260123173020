@import "src/utils/reset";

.nav {
  padding: 12px 64px;
  position: relative;
  width: 100%;
  background: linear-gradient(45deg, var(--background-light) 50%, var(--main-red) 50%);
  display: flex;
  gap: 96px;

  @media screen and (max-width: 1280px) {
    background: var(--main-red);
  }

  @media screen and (max-width: 1024px) {
    padding: 12px 24px;
    gap: 64px;
  }

  &_catalogue {
    min-width: 190px;
    min-height: 40px;
    padding: 16px;
    @include flex_center;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid var(--white);
    cursor: pointer;

    & > p {
      @include text(var(--white), 14px, 400, 114.286%);
      font-family: 'Open Sans', 'sans-serif';
      text-transform: uppercase;
    }
  }

  &_list {
    padding-right: 128px;
    //padding-right: 96px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 32px;

    @media screen and (max-width: 1280px) {
      padding: 0;
      gap: 16px;
    }

    &_item {
      @include text(var(--white), 14px, 400, 114.286%);
      font-family: 'Open Sans', sans-serif;
      text-transform: uppercase;
      cursor: pointer;
    }
  }
}
