@import "src/utils/reset";
@import "src/utils/mixins";

.productsBlockContainer {
  display: flex;
  flex-direction: column;
  gap: 56px;

  @media screen and (max-width: 768px) {
    gap: 32px;
  }

  &_title {
    @include block_title;
  }
}
