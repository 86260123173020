@import "src/utils/reset";

.wrapper {
  @include page_box;

  &_confirmEmailPage {
    @include container_max_width;
    display: flex;
    flex-direction: column;
    gap: 48px;

    &_title {
      @include main_title;
    }

    &_content {
      display: flex;
      border-radius: 4px;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }

      &_rectangle {
        width: 23%;
        min-height: 100%;
        background: var(--main-green);
        border-radius: 4px 0 0 4px;

        @media screen and (max-width: 768px) {
          width: 100%;
          min-height: 100px;
          border-radius: 4px;
        }
      }

      &_block {
        width: 77%;
        padding: 64px;
        display: flex;
        flex-direction: column;
        gap: 48px;
        border-radius: 0 4px 4px 0;
        background: var(--background-light);

        @media screen and (768px <= width <= 1024px) {
          padding: 32px;
        }

        @media screen and (max-width: 768px) {
          width: 100%;
          padding: 32px 16px;
          gap: 24px;
        }

        & > h2 {
          @include text(var(--white), 30px, 400, 133.333%);
        }

        & > p {
          @include text(var(--white), 18px, 400, 133.333%);
        }
      }
    }
  }
}
