@import "src/utils/reset";
@import "src/utils/mixins";

.emptyCartPage {
  width: 100%;
  height: 100%;
  @include flex_center;
  flex-direction: column;
  gap: 48px;

  @media screen and (max-width: 425px) {
    gap: 32px;
  }

  &_icon {
    width: 96px;
    height: 96px;

    @media screen and (max-width: 425px) {
      width: 64px;
      height: 64px;
    }
  }

  &_description {
    @include flex_center;
    flex-direction: column;

    &_title {
      color: var(--white);
      font-size: 30px;
      font-weight: 600;
      line-height: 133.333%;

      @media screen and (max-width: 425px) {
        font-size: 16px;
      }
    }

    &_subtitle {
      margin: 16px 0 32px;
      color: var(--white);
      font-size: 18px;
      line-height: 133.333%;
      text-align: center;

      @media screen and (max-width: 425px) {
        font-size: 14px;
      }
    }
  }
}
