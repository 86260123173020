@import "src/utils/reset";
@import "src/utils/mixins";

.backgroundOverlay {
  width: 100vw;
  height: 100vh;
  @include flex_center;

  &_close {
    position: absolute;
    top: 15px;
    right: 35px;
    @include text(#999999, 32px, 400, 150%);
    transition: 0.3s;
    cursor: pointer;
  }

  &_product {
    @include flex_center;
    flex-direction: column;
    gap: 72px;

    @media screen and (max-width: 768px) {
      gap: 32px;
    }

    &_name {
      @include text(var(--background-light), 30px, 400, 133.333%);
      font-family: 'NAMU', sans-serif;
    }

    &_photo {
      width: 50vw;
      height: 50vh;
      @include flex_center;

      @media screen and (max-width: 768px) {
        width: 80vw;
      }

      @media screen and (max-width: 425px) {
        width: 90vw;
        height: 35vh;
      }

      & > img {
        max-width: 100%;
        max-height: 100%;
        cursor: pointer;
      }
    }
  }
}
