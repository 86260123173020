@import "src/utils/reset";

.wrapper {
  min-width: 100vw;
  min-height: 100vh;
  background: var(--background-dark);
  display: flex;
  justify-content: center;

  &_mainLayout {
    position: relative;
    width: 100vw;
    min-height: 100vh;
    //max-width: 1600px;
    display: flex;
    flex-direction: column;

    &_flex {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
  }
}
