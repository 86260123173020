@import "src/utils/reset";

.form {
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  flex: 1;
  border-radius: 4px;
  background: var(--background-light);

  @media screen and (max-width: 425px) {
    padding: 32px 16px;
  }

  .flex2 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;

    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }
  }

  &_personal, &_shipping {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &_title {
      @include text(var(--white), 16px, 600, 150%);
    }
  }
}
