@import "src/utils/reset";

.cart {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  &_button {
    position: relative;

    &_count {
      width: 15px;
      height: 15px;
      position: absolute;
      bottom: -4px;
      right: -4px;
      padding: 4px;
      border-radius: 50%;
      background: var(--white);
      @include flex_center;
      @include text(var(--background-dark), 12px, 400, 133.33%);
    }
  }

  &_price {
    @include text(var(--white), 14px, 400, 114.286%);
    white-space: nowrap;

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }
}
