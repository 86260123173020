@import "src/utils/reset";

.userProfilePage {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 48px;

  &_title {
    display: flex;
    align-items: center;
    gap: 16px;

    img {
      display: flex;

      @media screen and (min-width: 768px) {
        display: none;
      }
    }

    h1 {
      @include block_title;
    }
  }

  &_content {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;

    &_right, &_left {
      min-width: 320px;
      display: flex;
      flex-direction: column;
      gap: 32px;
      flex: 1;

      @media screen and (max-width: 425px) {
        min-width: 100%;
      }
    }
  }
}
