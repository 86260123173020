@import "../../utils/reset";

.accordion {
  width: 100%;
  background: var(--background-light);
  padding: 16px 32px;
}

.partner {
  &_fullname {
    @include text(var(--white), 14px, 500, 171.429%);
  }

  &_id {
    @include text(var(--white), 14px, 400, 171.429%);

    & > span {
      @include text(var(--white), 14px, 500, 171.429%);
    }
  }
}

.buttons {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 16px;

  @media screen and (768px <= width <= 1024px) {
    flex-direction: column;
  }

  @media screen and (max-width: 550px) {
    flex-direction: column;
  }
}

// ---------------------------- desktop ----------------------------
.orderDesktop {
  padding: 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 32px;

  @media screen and (max-width: 1280px) {
    justify-content: flex-start;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }

  &_block {
    &_subtitle {
      @include text(#D9D9D9, 14px, 400, 171.429%);
    }

    &_info {
      @include text(var(--white), 14px, 500, 171.429%);
    }
  }
}

// ---------------------------- mobile ----------------------------
.orderMobile {
  padding: 8px 16px;
  width: 100%;
  display: none;
  flex-direction: column;
  gap: 16px;

  @media screen and (max-width: 768px) {
    display: flex;
  }

  &_orderId {
    @include text(#D9D9D9, 14px, 400, 171.429%);
  }

  &_spaceBetween {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    flex-wrap: wrap;

    .white {
      @include text(var(--white), 14px, 500, 171.429%);
    }

    .gray {
      @include text(#D9D9D9, 14px, 400, 171.429%);
    }

    &_block {
      display: flex;
      flex-direction: column;
    }
  }
}
