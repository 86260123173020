@import "src/utils/reset";

// ------------------------------------ desktop ------------------------------------
.headerDesktopContainer {
  @media screen and (max-width: 768px) {
    display: none;
  }

  &_header {
    padding: 24px 64px;
    background: var(--background-dark);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    @media screen and (768px <= width <= 1024px) {
      padding: 24px;
    }

    & > img {
      cursor: pointer;
    }

    &_details {
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }
}

// ------------------------------------ mobile ------------------------------------

.headerMobile {
  display: none;
  padding: 16px;
  background: linear-gradient(83deg, var(--background-dark) 50%, var(--main-red) 50%);
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  z-index: 100;

  @media screen and (max-width: 768px) {
    display: flex;

    &.open {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
    }
  }

  &_group {
    display: flex;
    align-items: center;
    gap: 16px;

    &_button {
      position: relative;

      &_count {
        width: 15px;
        height: 15px;
        position: absolute;
        bottom: -4px;
        right: -4px;
        padding: 4px;
        border-radius: 50%;
        background: var(--white);
        @include flex_center;
        @include text(var(--background-dark), 12px, 400, 133.33%);
      }

      &.hamburger {
        position: relative;
        display: block;
        width: 18px;
        cursor: pointer;

        @media screen and (min-width: 769px) {
          display: none;
        }

        .bar, &.hamburger:after, &.hamburger:before {
          content: '';
          display: block;
          width: 100%;
          height: 1.5px;
          background-color: var(--white);
          margin: 6px 0;
          transition: 0.3s;
        }

        &.open:before {
          transform: rotate(-45deg) translate(-3px, 6px);
          background-color: var(--main-red);
          transition: 0.3s;
        }

        &.open:after {
          transform: rotate(45deg) translate(-5px, -8px);
          background-color: var(--main-red);
          transition: 0.3s;
        }

        &.open .bar {
          opacity: 0;
        }
      }
    }
  }
}

.mobileNav {
  position: fixed;
  top: 0;
  right: 100%;
  width: 100%;
  min-height: 100vh;
  z-index: 98;
  background-color: var(--background-dark);
  padding: 162px 16px 48px;
  transition: 0.4s;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &_link {
    @include text(var(--white), 20px, 400, 160%);
    font-family: 'NAMU', sans-serif;

    &.loggedIn {
      & > span {
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: var(--white);
        -webkit-text-fill-color: transparent;
        @include text(var(--white), 20px, 400, 160%);
      }
    }
  }

  @media screen and (min-width: 769px) {
    display: none;
  }

  &.open {
    right: 0;
  }
}
