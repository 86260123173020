@import "src/utils/reset";
@import "src/utils/mixins";

.searchResultsList {
  position: absolute;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  padding: 12px;
  border-radius: 0 0 4px 4px;
  background: var(--background-dark);
  z-index: 10;
  transition: 0.3s;

  &_item {
    padding: 8px;
    border-radius: 4px;
    @include text(var(--white), 14px, 400, 171.429%);
    transition: 0.3s;

    &:hover {
      background: var(--background-light);
      transition: 0.3s;
    }
  }
}
