@import "reset";

.image-gallery-thumbnail {
  &.active, &:hover {
    border-color: var(--white);
  }
}

.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
  width: 32px;
  height: 96px;
}

.image-gallery-icon {
  filter: none;

  &:hover {
    color: var(--white);
  }
}

.image-gallery-bullets .image-gallery-bullet {
  &:hover {
    border-color: var(--white);
    background: var(--white);
  }

  .active {
    &:hover {
      border-color: var(--white);
      background: var(--white);
    }
  }
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: none;
}

.image-gallery-slide .image-gallery-image {
  min-width: 100%;
  min-height: 100%;
  object-fit: fill;
}
