@import "src/utils/reset";

.item {
  max-width: 230px;
  border-radius: 4px;
  background: var(--white);
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;

  &_top {
    width: 62.5%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &_photo {
      width: 48px;
      height: 48px;
      border-radius: 4px;
    }

    &_rank {
      padding: 0 8px;
      @include text(var(--white), 14px, 600, 142.857%);
      border-radius: 4px;
      background: var(--main-red);
    }
  }

  &_user {
    width: 100%;
    @include flex_center;
    flex-direction: column;

    &_fullname {
      @include text(var(--background-dark), 16px, 600, 150%);
    }

    &_phoneNumber {
      @include text(var(--background-dark), 14px, 400, 142.857%);
    }

    &_activity {
      @include text(var(--main-green), 14px, 400, 142.857%);
    }
  }
}
