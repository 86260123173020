@import "src/utils/reset";

.detailedItem {
  padding: 16px 24px;
  border-radius: 4px;
  background: var(--background-light);
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &_user {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 16px;

    @media screen and (375px <= width <= 425px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto;
      gap: 16px;
    }

    @media screen and (max-width: 375px) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
    }

    &_info {
      display: flex;
      gap: 24px;

      &_photo {
        width: 48px;
        height: 48px;
        border-radius: 4px;
        border: 3px solid var(--white);
      }

      &_name {
        @include text(var(--white), 16px, 600, 150%);
      }

      &_rank {
        @include text(var(--white), 14px, 500, 171.429%);
      }
    }

    &_block {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @media screen and (max-width: 375px) {
        width: 100%;
      }

      &_title {
        @include text(#D9D9D9, 14px, 400, 171.429%);
      }

      &_data {
        @include text(var(--white), 14px, 500, 171.429%);
      }
    }
  }
}
