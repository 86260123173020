.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.Mui-expanded.MuiAccordion-gutters {
    margin: 0;
}

.MuiButtonBase-root.MuiAccordionSummary-root.MuiAccordionSummary-gutters {
    min-height: 0;
    padding: 0;

    .MuiAccordionSummary-content {
        margin: 0;

        .Mui-expanded {
            margin: 0;
        }
    }
}
