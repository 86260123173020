@import "src/utils/reset";
@import "src/utils/mixins";

.progressBar {
  display: flex;
  flex-direction: column;
  gap: 4px;

  &_label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_name {
      @include text(var(--white), 14px, 400, 171.429%);
    }

    &_count {
      @include text(var(--white), 14px, 500, 171.429%);
    }
  }
}
