@import "src/utils/reset";

.productItemOrderRecommendations {
  padding: 16px 32px;
  border-radius: 4px;
  background: var(--background-light);
  display: flex;
  align-items: center;
  gap: 32px;

  @media screen and (max-width: 768px) {
    align-items: flex-start;
  }

  @media screen and (max-width: 425px) {
    gap: 24px;
  }

  &_photo {
    width: 75px;
    height: 80px;

    @media screen and (max-width: 425px) {
      width: 48px;
      height: 48px;
    }
  }

  &_description {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    @media screen and (max-width: 768px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, auto);
      gap: 16px 16px;
      grid-template-areas:
    "title title"
    "addGroup addGroup"
    }

    &_title {
      display: flex;
      flex-direction: column;
      gap: 16px;

      @media screen and (max-width: 768px) {
        grid-area: title;
        gap: 0;
      }

      &_name {
        &_text {
          @include text(var(--white), 16px, 400, 150%);
        }

        &_code {
          @include text(#D9D9D9, 14px, 400, 171.429%);

          & > span {
            @include text(var(--white), 14px, 400, 24px);
          }
        }
      }

      &_points {
        @include text(var(--white), 14px, 400, 171.429%);
      }
    }

    &_addGroup {
      display: flex;
      align-items: center;
      gap: 64px;

      @media screen and (max-width: 768px) {
        grid-area: addGroup;
        justify-content: space-between;
        gap: 16px;
      }

      &_price {
        @media screen and (max-width: 768px) {
          display: flex;
          align-items: center;
          gap: 8px;
        }

        @media screen and (max-width: 375px) {
          flex-direction: column;
          gap: 0;
        }

        &_old {
          @include text(#999, 18px, 400, 133.333%);
          text-decoration: line-through;
        }

        &_new {
          @include text(var(--main-red), 24px, 600, 166.667%);
        }

        @media screen and (max-width: 640px) {
          &_old {
            font-size: 14px;
          }

          &_new {
            font-size: 16px;
          }
        }
      }

      &_button {
        width: 48px;
        height: 48px;
        @include flex_center;
        border-radius: 4px;
        border: 1px solid var(--white);
        @include text(var(--white), 24px, 400, 171.429%);
        transition: 0.3s;

        &:hover {
          transition: 0.3s;
          background: var(--white);
          color: var(--background-light);
        }
      }
    }
  }
}
