@import "src/utils/reset";

.item {
  margin-top: 24px;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 32px;

  @media screen and (max-width: 1024px) {
    justify-content: flex-start;
  }

  @media screen and (max-width: 425px) {
    display: none;
  }

  &_details {
    min-width: 225px;
    display: flex;
    align-items: center;
    gap: 32px;

    & > img {
      width: 55px;
      height: 60px;
    }

    & > div {
      h3 {
        max-width: 350px;
        @include text(var(--white), 14px, 500, 171.429%);
      }

      p {
        @include text(#D9D9D9, 14px, 400, 171.429%);

        span {
          @include text(var(--white), 14px, 400, 171.429%);
        }
      }
    }
  }


  p {
    @include text(var(--white), 14px, 400, 171.429%);
  }
}

.itemMobile {
  margin-top: 24px;
  display: none;
  gap: 32px;
  align-items: flex-start;

  @media screen and (max-width: 425px) {
    display: flex;
  }

  & > img {
    width: 55px;
    height: 60px;
  }

  &_details {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;

    &_col {
      display: flex;
      flex-direction: column;
      gap: 4px;

      h3 {
        @include text(var(--white), 14px, 500, 171.429%);
      }

      p {
        @include text(#D9D9D9, 14px, 400, 171.429%);

        span {
          @include text(var(--white), 14px, 400, 171.429%);
        }
      }
    }

    &_jsBetween {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & > p {
        @include text(var(--white), 14px, 400, 171.429%);
      }
    }
  }
}
