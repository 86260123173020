@import "src/utils/reset";

.myOrderPriceGiftBonus {
  max-width: 415px;
  min-width: 415px;
  padding: 32px;
  border-radius: 4px;
  background: var(--background-light);

  @media screen and (max-width: 1280px) {
    max-width: none;
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    min-width: 0;
  }

  @media screen and (max-width: 425px) {
    padding: 32px 16px;
  }

  .wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .divider {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid var(--border);
    margin: 16px 0;
  }

  &_total {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &_points {
      &_title {
        @include text(var(--white), 16px, 500, 133.33%);

        @media screen and (max-width: 640px) {
          font-size: 14px;
        }
      }

      &_count {
        @include text(var(--white), 18px, 600, 133.33%);

        @media screen and (max-width: 425px) {
          font-size: 16px;
        }
      }
    }
  }

  &_points {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &_title {
      @include text(var(--white), 18px, 500, 133.33%);

      @media screen and (max-width: 425px) {
        font-size: 16px;
      }
    }

    &_count {
      @include text(var(--white), 24px, 600, 133.33%);

      @media screen and (max-width: 425px) {
        font-size: 16px;
      }
    }
  }

  &_giftBalance {
    &_title {
      @include text(var(--main-red), 16px, 500, 24px);
    }

    &_count {
      @include text(var(--main-red), 16px, 500, 24px);
    }
  }

  &_deliveryPayment {
    gap: 16px;

    &_title {
      @include text(var(--white), 14px, 500, 171.429%);
    }

    &_description {
      @include text(var(--white), 16px, 500, 171.429%);
      text-align: right;
    }
  }

  &_buttons {
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
