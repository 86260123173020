@import "src/utils/reset";

.ourStory {
  width: 100%;
  display: flex;
  gap: 32px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }

  & > img {
    width: 35%;
    max-height: 650px;
    border-radius: 4px;

    @media screen and (max-width: 1280px) {
      width: 50%;
      height: fit-content;
    }

    @media screen and (max-width: 640px) {
      width: 100%;
    }
  }

  &_description {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media screen and (max-width: 768px) {
      width: 100%;
    }

    @media screen and (max-width: 1280px) {
      gap: 0;
    }

    &_subtitle {
      display: flex;
      flex-direction: column;
      gap: 8px;

      @media screen and (max-width: 1280px) {
        margin-bottom: 24px;
      }
    }

    & > p {
      @include text(var(--white), 16px, 400, 150%);
      vertical-align: bottom;
    }

    .red {
      @include text(var(--main-red), 16px, 700, 133.33%);

      &.uppercase {
        @include text(var(--main-red), 18px, 700, 133.33%);
        text-transform: uppercase;
      }
    }

    .white {
      @include text(var(--white), 18px, 700, 133.33%);
      text-transform: uppercase;
    }
  }
}
