@import "../../utils/reset";

.dropdown_wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4px;

  &_label {
    margin-bottom: 4px;
    @include text(var(--white), 16px, 500, 150%);
  }

  &_button {
    padding: 8px 12px;
    border-radius: 4px;
    border: 1px solid var(--border);
    @include text(var(--white), 14px, 400, 171.429%);
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > img {
      cursor: pointer;
      transform: rotate(90deg);
    }
  }

  &_options {
    z-index: 10;
    width: 100%;
    max-height: 200px;
    overflow: auto;
    position: absolute;
    top: 78px;
    border-radius: 4px;
    border: 1px solid var(--border);
    background: rgb(15, 15, 15);

    & > li {
      padding: 8px 12px;
      border-top: 1px solid var(--border);
      @include text(var(--white), 14px, 400, 171.429%);
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: 0.3s;

      &:first-child {
        border-top: none;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }

      &:hover {
        background: rgba(29, 29, 29, 0.95);
        transition: 0.3s;
      }
    }
  }
}
