@import "src/utils/reset";

.progressBar {
  position: relative;
  @include flex_center;

  &_text {
    position: absolute;
    @include text(var(--white), 16px, 500, 150%);
    word-break: break-word;
    max-width: 85%;
    @include flex_center;
    flex-direction: column;
    text-align: center;
  }
}
