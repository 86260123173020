@import "src/utils/reset";

.wrapper {
  @include page_box;

  &_cartPage {
    @include container_max_width;
    display: flex;
    flex-direction: column;
    gap: 48px;

    @media screen and (max-width: 425px) {
      gap: 32px;
    }

    &_title {
      @include main_title;
    }

    &_content {
      display: flex;
      align-items: flex-start;
      gap: 32px;

      @media screen and (max-width: 1280px) {
        flex-direction: column;
      }

      &_products {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 32px;
      }
    }
  }
}
