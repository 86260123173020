@import "src/utils/reset";

.listOfProducts {
  padding: 24px 32px;
  border-radius: 4px;
  background: var(--background-light);
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media screen and (max-width: 425px) {
    padding: 16px;
  }

  &_title {
    color: var(--white);
    font-size: 20px;
    font-weight: 700;
    line-height: 160%;
  }

  &_list {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}
