@import "../../utils/reset";

.button {
  padding: 12px 40px;
  border-radius: 4px;
  background: var(--white);
  border: 1px solid var(--white);
  @include text(var(--background-dark), 16px, 700, 150%);
  user-select: none;
  transition: 0.3s;

  &:hover {
    transform: scale(0.95);
    transition: 0.3s;
  }
}
