@import "src/utils/reset";

.deliveryAddresses {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &.view {
    max-height: 500px;
    overflow-y: auto;
    padding: 32px 24px;
    border-radius: 4px;
    background: var(--background-light);

    @media screen and (max-width: 768px) {
      padding: 24px 16px;
    }
  }

  &_title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_name {
      @include text(var(--white), 18px, 500, 155.556%);
    }

    & > img {
      cursor: pointer;
    }
  }

  &_divider {
    width: 100%;
    height: 1px;
    background: var(--border);
  }
}
