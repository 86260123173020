.swiper-pagination-bullet {
  width: 60px;
  height: 6px;
  background: #3F3F3F;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  opacity: 1;

  @media screen and (max-width: 425px) {
    width: 40px;
    height: 4px;
  }
}

.swiper-pagination-bullet-active::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: var(--white);
  animation: progress 5.3s linear infinite;
}

@keyframes progress {
  0% {
    transform: scaleX(0);
    transform-origin: left;
  }
  100% {
    transform: scaleX(1);
    transform-origin: left;
  }
}

.swiper-button-next, .swiper-button-prev {
  width: 48px;
  height: 48px;
  border: 1px solid var(--white);
  border-radius: 4px;
  background-size: cover;

  @media screen and (max-width: 425px) {
    width: 32px;
    height: 32px;
  }
}

.swiper-button-next {
  background-image: url('../assets/chevron-right.svg');
  transition: 0.3s;

  &:hover {
    background: rgba(255, 255, 255, 0.60) url('../assets/chevron-right-hover.svg');
    background-size: cover;
    transition: 0.3s;
  }
}

.swiper-button-prev {
  background-image: url('../assets/chevron-left.svg');
  transition: 0.3s;

  &:hover {
    background: rgba(255, 255, 255, 0.60) url('../assets/chevron-left-hover.svg');
    background-size: cover;
    transition: 0.3s;
  }
}

.swiper-button-next::after, .swiper-button-prev::after {
  display: none;
}
