@import "src/utils/reset";

.badge {
  min-width: 200px;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid #D9DBDD;
  @include text(var(--white), 16px, 400, 150%);
  text-align: center;
  text-transform: uppercase;
}
