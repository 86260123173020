@import "src/utils/reset";

.deliveryMethods {
  width: 100%;
  padding: 16px;
  border-radius: 4px;
  border: 1px solid var(--white);
  display: flex;
  flex-direction: column;
  gap: 24px;

  &_title {
    display: flex;
    align-items: center;
    gap: 8px;

    & > p {
      color: var(--white);
      font-size: 16px;
      font-weight: 600;
      line-height: 150%;
    }
  }

  &_method {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &_title {
      color: var(--white);
      font-size: 16px;
      font-weight: 500;
      line-height: 150%;
    }

    &_prices {
      display: flex;
      flex-direction: column;
      gap: 4px;

      & > p {
        color: #D9D9D9;
        font-size: 14px;
        font-weight: 500;
        line-height: 171.429%
      }
    }
  }
}
