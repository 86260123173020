@import "src/utils/reset";

.loader {
  width: 100vw;
  height: 100vh;
  position: relative;
  @include flex_center;
  background-color: var(--background-dark);

  &_dot {
    border-radius: 100%;
    height: 24px;
    width: 24px;
    background-color: var(--main-red);
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
    box-shadow: 0px 40px 0px 0px var(--main-red), 0px -40px 0px 0px var(--main-red), 40px 0px 0px 0px var(--main-red), -40px 0px 0px 0px var(--main-red),
    40px 40px 0px 0px var(--main-red), -40px -40px 0px 0px var(--main-red), 40px -40px 0px 0px var(--main-red), -40px 40px 0px 0px var(--main-red);
    -webkit-animation: load 3s infinite linear;
    -moz-animation: load 3s infinite linear;
    -o-animation: load 3s infinite linear;
    animation: load 3s infinite linear;
  }
}

@-webkit-keyframes load {
  0% {
    -webkit-transform: translate(0px, 0px), rotate(0deg);
    box-shadow: 0px 40px 0px 0px var(--main-red), 0px -40px 0px 0px var(--main-red), 40px 0px 0px 0px var(--main-red), -40px 0px 0px 0px var(--main-red),
    40px 40px 0px 0px var(--main-red), -40px -40px 0px 0px var(--main-red), 40px -40px 0px 0px var(--main-red), -40px 40px 0px 0px var(--main-red);
  }

  25% {
    box-shadow: 0px 40px 0px 0px var(--main-red), 0px -40px 0px 0px var(--main-red), 40px 0px 0px 0px var(--main-red), -40px 0px 0px 0px var(--main-red),
    -40px -40px 0px 0px var(--main-red), 40px 40px 0px 0px var(--main-red), -40px 40px 0px 0px var(--main-red), 40px -40px 0px 0px var(--main-red);
  }

  50% {
    box-shadow: 0px -40px 0px 0px var(--main-red), 0px 40px 0px 0px var(--main-red), -40px 0px 0px 0px var(--main-red), 40px 0px 0px 0px var(--main-red),
    -40px -40px 0px 0px var(--main-red), 40px 40px 0px 0px var(--main-red), -40px 40px 0px 0px var(--main-red), 40px -40px 0px 0px var(--main-red);
  }

  70% {
    box-shadow: 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red),
    0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red);
  }

  75% {
    box-shadow: 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red),
    0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red);
    height: 10px;
    width: 10px;
  }

  80% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    box-shadow: 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red),
    0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red);
    background-color: var(--main-red);
    height: 40px;
    width: 40px;
  }

  85% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    box-shadow: 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red),
    0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red);
    background-color: var(--main-red);
    height: 40px;
    width: 40px;
  }

  90% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    box-shadow: 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red),
    0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red);
    background-color: var(--main-red);
    height: 10px;
    width: 10px;
  }

  95% {
    box-shadow: 0px 40px 0px 0px var(--main-red), 0px -40px 0px 0px var(--main-red), 40px 0px 0px 0px var(--main-red), -40px 0px 0px 0px var(--main-red),
    40px 40px 0px 0px var(--main-red), -40px -40px 0px 0px var(--main-red), 40px -40px 0px 0px var(--main-red), -40px 40px 0px 0px var(--main-red);
  }

  100% {
    -webkit-transform: rotate(360deg);
    box-shadow: 0px 40px 0px 0px var(--main-red), 0px -40px 0px 0px var(--main-red), 40px 0px 0px 0px var(--main-red), -40px 0px 0px 0px var(--main-red),
    40px 40px 0px 0px var(--main-red), -40px -40px 0px 0px var(--main-red), 40px -40px 0px 0px var(--main-red), -40px 40px 0px 0px var(--main-red);
  }

}

@-moz-keyframes load {
  0% {
    -moz-transform: translate(0px, 0px), rotate(0deg);
    box-shadow: 0px 40px 0px 0px var(--main-red), 0px -40px 0px 0px var(--main-red), 40px 0px 0px 0px var(--main-red), -40px 0px 0px 0px var(--main-red),
    40px 40px 0px 0px var(--main-red), -40px -40px 0px 0px var(--main-red), 40px -40px 0px 0px var(--main-red), -40px 40px 0px 0px var(--main-red);
  }

  25% {
    box-shadow: 0px 40px 0px 0px var(--main-red), 0px -40px 0px 0px var(--main-red), 40px 0px 0px 0px var(--main-red), -40px 0px 0px 0px var(--main-red),
    -40px -40px 0px 0px var(--main-red), 40px 40px 0px 0px var(--main-red), -40px 40px 0px 0px var(--main-red), 40px -40px 0px 0px var(--main-red);
  }

  50% {
    box-shadow: 0px -40px 0px 0px var(--main-red), 0px 40px 0px 0px var(--main-red), -40px 0px 0px 0px var(--main-red), 40px 0px 0px 0px var(--main-red),
    -40px -40px 0px 0px var(--main-red), 40px 40px 0px 0px var(--main-red), -40px 40px 0px 0px var(--main-red), 40px -40px 0px 0px var(--main-red);
  }

  70% {
    box-shadow: 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red),
    0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red);
  }

  75% {
    box-shadow: 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red),
    0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red);
    height: 10px;
    width: 10px;
  }

  80% {
    -moz-transform: translate(0px, 0px) rotate(360deg);
    box-shadow: 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red),
    0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red);
    background-color: var(--main-red);
    height: 40px;
    width: 40px;
  }

  85% {
    -moz-transform: translate(0px, 0px) rotate(360deg);
    box-shadow: 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red),
    0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red);
    background-color: var(--main-red);
    height: 40px;
    width: 40px;
  }

  90% {
    -moz-transform: translate(0px, 0px) rotate(360deg);
    box-shadow: 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red),
    0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red);
    background-color: var(--main-red);
    height: 10px;
    width: 10px;
  }

  95% {
    box-shadow: 0px 40px 0px 0px var(--main-red), 0px -40px 0px 0px var(--main-red), 40px 0px 0px 0px var(--main-red), -40px 0px 0px 0px var(--main-red),
    40px 40px 0px 0px var(--main-red), -40px -40px 0px 0px var(--main-red), 40px -40px 0px 0px var(--main-red), -40px 40px 0px 0px var(--main-red);
  }

  100% {
    -moz-transform: rotate(360deg);
    box-shadow: 0px 40px 0px 0px var(--main-red), 0px -40px 0px 0px var(--main-red), 40px 0px 0px 0px var(--main-red), -40px 0px 0px 0px var(--main-red),
    40px 40px 0px 0px var(--main-red), -40px -40px 0px 0px var(--main-red), 40px -40px 0px 0px var(--main-red), -40px 40px 0px 0px var(--main-red);
  }

}

@keyframes load {
  0% {
    transform: translate(0px, 0px), rotate(0deg);
    box-shadow: 0px 40px 0px 0px var(--main-red), 0px -40px 0px 0px var(--main-red), 40px 0px 0px 0px var(--main-red), -40px 0px 0px 0px var(--main-red),
    40px 40px 0px 0px var(--main-red), -40px -40px 0px 0px var(--main-red), 40px -40px 0px 0px var(--main-red), -40px 40px 0px 0px var(--main-red);
  }

  25% {
    box-shadow: 0px 40px 0px 0px var(--main-red), 0px -40px 0px 0px var(--main-red), 40px 0px 0px 0px var(--main-red), -40px 0px 0px 0px var(--main-red),
    -40px -40px 0px 0px var(--main-red), 40px 40px 0px 0px var(--main-red), -40px 40px 0px 0px var(--main-red), 40px -40px 0px 0px var(--main-red);
  }

  50% {
    box-shadow: 0px -40px 0px 0px var(--main-red), 0px 40px 0px 0px var(--main-red), -40px 0px 0px 0px var(--main-red), 40px 0px 0px 0px var(--main-red),
    -40px -40px 0px 0px var(--main-red), 40px 40px 0px 0px var(--main-red), -40px 40px 0px 0px var(--main-red), 40px -40px 0px 0px var(--main-red);
  }

  70% {
    box-shadow: 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red),
    0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red);
  }

  75% {
    box-shadow: 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red),
    0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red);
    height: 10px;
    width: 10px;
  }

  80% {
    transform: translate(0px, 0px) rotate(360deg);
    box-shadow: 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red),
    0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red);
    background-color: var(--main-red);
    height: 40px;
    width: 40px;
  }

  85% {
    transform: translate(0px, 0px) rotate(360deg);
    box-shadow: 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red),
    0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red);
    background-color: var(--main-red);
    height: 40px;
    width: 40px;
  }

  90% {
    transform: translate(0px, 0px) rotate(360deg);
    box-shadow: 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red),
    0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red);
    background-color: var(--main-red);
    height: 10px;
    width: 10px;
  }

  95% {
    box-shadow: 0px 40px 0px 0px var(--main-red), 0px -40px 0px 0px var(--main-red), 40px 0px 0px 0px var(--main-red), -40px 0px 0px 0px var(--main-red),
    40px 40px 0px 0px var(--main-red), -40px -40px 0px 0px var(--main-red), 40px -40px 0px 0px var(--main-red), -40px 40px 0px 0px var(--main-red);
  }

  100% {
    transform: rotate (360deg);
    box-shadow: 0px 40px 0px 0px var(--main-red),
    0 px

-
40 px 0px 0px var(--main-red),
    40px 0px 0px 0px var(--main-red),
    -
40 px 0px 0px 0px var(--main-red),
    40px 40px 0px 0px var(--main-red),
    -
40 px

-
40 px 0px 0px var(--main-red),
    40 px

-
40 px 0px 0px var(--main-red),
    -
40 px 40px 0px 0px var(--main-red);
  }

}

@-o-keyframes load {
  0% {
    -o-transforms: translate(0px, 0px), rotate(0deg);
    box-shadow: 0px 40px 0px 0px var(--main-red), 0px -40px 0px 0px var(--main-red), 40px 0px 0px 0px var(--main-red), -40px 0px 0px 0px var(--main-red),
    40px 40px 0px 0px var(--main-red), -40px -40px 0px 0px var(--main-red), 40px -40px 0px 0px var(--main-red), -40px 40px 0px 0px var(--main-red);
  }

  25% {
    box-shadow: 0px 40px 0px 0px var(--main-red), 0px -40px 0px 0px var(--main-red), 40px 0px 0px 0px var(--main-red), -40px 0px 0px 0px var(--main-red),
    -40px -40px 0px 0px var(--main-red), 40px 40px 0px 0px var(--main-red), -40px 40px 0px 0px var(--main-red), 40px -40px 0px 0px var(--main-red);
  }

  50% {
    box-shadow: 0px -40px 0px 0px var(--main-red), 0px 40px 0px 0px var(--main-red), -40px 0px 0px 0px var(--main-red), 40px 0px 0px 0px var(--main-red),
    -40px -40px 0px 0px var(--main-red), 40px 40px 0px 0px var(--main-red), -40px 40px 0px 0px var(--main-red), 40px -40px 0px 0px var(--main-red);
  }

  70% {
    box-shadow: 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red),
    0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red);
  }

  75% {
    box-shadow: 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red),
    0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red);
    height: 10px;
    width: 10px;
  }

  80% {
    -o-transforms: translate(0px, 0px) rotate(360deg);
    box-shadow: 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red),
    0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red);
    background-color: var(--main-red);
    height: 40px;
    width: 40px;
  }

  85% {
    -o-transforms: translate(0px, 0px) rotate(360deg);
    box-shadow: 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red),
    0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red);
    background-color: var(--main-red);
    height: 40px;
    width: 40px;
  }

  90% {
    -o-transforms: translate(0px, 0px) rotate(360deg);
    box-shadow: 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red),
    0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red), 0px 0px 0px 0px var(--main-red);
    background-color: var(--main-red);
    height: 10px;
    width: 10px;
  }

  95% {
    box-shadow: 0px 40px 0px 0px var(--main-red), 0px -40px 0px 0px var(--main-red), 40px 0px 0px 0px var(--main-red), -40px 0px 0px 0px var(--main-red),
    40px 40px 0px 0px var(--main-red), -40px -40px 0px 0px var(--main-red), 40px -40px 0px 0px var(--main-red), -40px 40px 0px 0px var(--main-red);
  }

  100% {
    -o-transforms: rotate (
            360deg
    );
    box-shadow: 0px 40px 0px 0px var(--main-red),
    0 px

-
40 px 0px 0px var(--main-red),
    40px 0px 0px 0px var(--main-red),
    -
40 px 0px 0px 0px var(--main-red),
    40px 40px 0px 0px var(--main-red),
    -
40 px

-
40 px 0px 0px var(--main-red),
    40 px

-
40 px 0px 0px var(--main-red),
    -
40 px 40px 0px 0px var(--main-red);
  }

}
