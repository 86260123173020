@import "src/utils/reset";

.priceRange {
  margin-top: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &_inputs {
    display: flex;
    align-items: center;
    gap: 8px;

    &_label {
      width: 100%;
      @include label;

      &_input {
        width: 100%;
        @include input;
      }
    }
  }
}
