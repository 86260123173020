@import "src/utils/reset";

.label {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  @include text(var(--white), 16px, 400, 150%);

  &_datePicker {
    width: 100%;

    & > div {
      height: 42px;

      & > input {
        padding: 8px 12px;
        height: auto;
        @include text(var(--white), 16px, 400, 150%);

        //&::placeholder {
        //  @include text(var(--white), 16px, 400, 150%);
        //}
      }

      & > fieldset {
        border: 1px solid var(--border) !important;
      }
    }
  }
}
