@import "src/utils/reset";

.wrapper {
  width: 100%;

  @media screen and (max-width: 768px) {
    @include flex_center;
  }
}

// -------------------------------------------- desktop -----------------------------------------

.pagination_desktop {
  display: flex;
  gap: 8px;

  @media screen and (max-width: 426px) {
    display: none;
  }
}

.page {
  padding: 10px;
  min-width: 40px;
  min-height: 40px;
  @include text(var(--white), 14px, 700, 142.857%);
  border: 1px solid var(--white);
  border-radius: 8px;
  background: transparent;
  cursor: pointer;
  @include flex_center;

  &.active {
    background: var(--white);
    @include text(var(--background-dark), 14px, 700, 142.857%);
  }

  &.disabled {
    opacity: 0.5;
    cursor: auto;
  }
}

// -------------------------------------------- mobile -----------------------------------------

.pagination_mobile {
  display: none;
  gap: 8px;

  @media screen and (max-width: 425px) {
    display: flex;
  }
}
