@import "src/utils/reset";

.whoAreWe {
  width: 100%;
  background: var(--white);
  display: flex;
  gap: 32px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  & > img {
    width: 50%;
    height: fit-content;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  &_description {
    width: 50%;
    padding: 48px 64px 48px 0;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media screen and (max-width: 1280px) {
      padding-right: 32px;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
      padding: 0 32px 48px;
      gap: 16px;
    }

    @media screen and (max-width: 425px) {
      padding: 0 16px 32px;
    }

    .text {
      @include text(var(--background-dark), 16px, 400, 150%);

      @media screen and (max-width: 768px) {
        font-size: 14px;
      }
    }

    &_title {
      display: flex;
      flex-direction: column;

      & > h2 {
        @include text(var(--background-dark), 64px, 400, 137.5%);
        font-family: "NAMU", sans-serif;
        text-transform: uppercase;

        @media screen and (max-width: 768px) {
          @include text(var(--background-dark), 24px, 400, 133.33%);
        }
      }
    }

    &_top {
      & > p:nth-child(1):first-child:first-letter {
        @include text(var(--background-dark), 48px, 600, 116.667%);
        initial-letter: 2;
        margin-right: 4px;

        @media screen and (max-width: 768px) {
          @include text(var(--background-dark), 24px, 600, 133.333%);
        }
      }
    }

    &_center {
      display: flex;
      gap: 32px;

      & > img {
        width: 25%;
        border-radius: 4px;

        @media screen and (max-width: 1280px) {
          display: none;
        }
      }

      &_text {
        display: flex;
        flex-direction: column;

        & > p:nth-child(1) {
          @include text(var(--background-dark), 64px, 400, 137.5%);
          font-family: "NAMU", sans-serif;
          text-transform: uppercase;

          @media screen and (max-width: 768px) {
            @include text(var(--background-dark), 24px, 400, 133.333%);
          }
        }
      }
    }
  }
}
