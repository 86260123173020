@import "src/utils/mixins";

.wrapper {
  @include page_box;

  &_recoveryPasswordPage {
    @include container_max_width;
    display: flex;
    flex-direction: column;
    gap: 48px;

    &_title {
      @include main_title;
    }

    &_content {
      display: flex;
      border-radius: 4px;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }

      &_rectangle {
        width: 23%;
        min-height: 100%;
        background: var(--main-green);
        border-radius: 4px 0 0 4px;

        @media screen and (max-width: 768px) {
          width: 100%;
          min-height: 100px;
          border-radius: 4px;
        }
      }

      &_form {
        width: 77%;
        padding: 64px;
        display: flex;
        flex-direction: column;
        gap: 48px;
        border-radius: 0 4px 4px 0;
        background: var(--background-light);

        @media screen and (768px <= width <= 1024px) {
          padding: 32px;
        }

        @media screen and (max-width: 768px) {
          width: 100%;
          padding: 32px 16px;
          gap: 24px;
        }

        & > div:nth-child(1) {
          display: flex;
          flex-direction: column;
          gap: 8px;

          h2 {
            @include text(var(--white), 30px, 400, 133.333%);
          }
        }

        p {
          @include text(var(--white), 16px, 400, 133.333%);

          span {
            color: var(--main-green);
            font-weight: 600;
          }
        }

        &_block {
          width: 50%;

          &_label {
            @include label;

            & > input {
              @include input;
            }

            & > span {
              @include text(var(--error), 14px, 400, 171.429%);
            }
          }

          @media screen and (max-width: 768px) {
            width: 100%;
          }
        }

        &_buttons {
          width: 100%;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          gap: 16px;

          @media screen and (max-width: 768px) {
            flex-direction: column-reverse;
            gap: 24px;
          }
        }
      }
    }
  }
}
