@import "src/utils/reset";

.productInfo {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 32px;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }

  &_photo {
    width: 100%;
    height: 100%;
    max-height: 800px;
    display: flex;
    justify-content: center;

    & > img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &_description {
    min-width: 415px;
    max-width: 415px;

    @media screen and (max-width: 1024px) {
      width: 100%;
      min-width: 0;
      max-width: none;
    }

    &_basic {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;

      //&_rating {
      //  display: flex;
      //  align-items: center;
      //  gap: 16px;
      //
      //  &_count {
      //    @include text(var(--white), 16px, 500, 150%);
      //  }
      //}

      &_name {
        @include text(var(--white), 30px, 400, 133.333%);
        font-family: 'NAMU', sans-serif;
      }

      &_code {
        @include text(#D9D9D9, 16px, 400, 150%);

        & > span {
          @include text(var(--white), 16px, 400, 24px);
        }
      }

      &_price {
        padding-top: 8px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &_content {
          display: flex;
          align-items: center;
          gap: 24px;

          &_new {
            @include text(var(--main-red), 30px, 600, 133.333%);
          }

          &_old {
            @include text(#999, 20px, 400, 120%);
            text-decoration: line-through;
          }

          &_regular {
            @include text(#999, 30px, 600, 133.333%);
          }
        }

        &_points {
          @include text(var(--white), 16px, 400, 150%);
        }
      }
    }

    &_buttons {
      padding: 48px 0;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
}
