@import "src/utils/reset";

.favourite {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  &_label {
    @include text(var(--white), 14px, 400, 114.286%);

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }
}
