@import "src/utils/reset";

.status {
  padding: 4px 12px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 171.429%;

  &.active {
    color: #0DBD14;
    background: rgba(13, 189, 20, 0.15);
  }

  &.inactive {
    color: var(--error);
    background: rgba(231, 18, 31, 0.15);
  }
}
