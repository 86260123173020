@import "src/utils/reset";

.historyWithdrawalPoints {
  width: 100%;
  padding: 24px;
  border-radius: 4px;
  background: var(--background-light);
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (max-width: 768px) {
    padding: 24px 16px;
  }

  .title {
    @include text(var(--white), 18px, 500, 133.333%);
  }

  &_divider {
    width: 100%;
    height: 1px;
    background: var(--border);
  }

  &_list {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &_item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      @include text(var(--white), 14px, 400, 24px);
    }
  }
}
