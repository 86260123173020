@import "src/utils/reset";

.wrapper {
  @include page_box;

  &_blogPage {
    @include container_max_width;
    display: flex;
    flex-direction: column;
    gap: 96px;

    @media screen and (max-width: 425px) {
      gap: 48px;
    }

    &_top {
      display: flex;
      flex-direction: column;
      gap: 32px;

      &_title {
        @include main_title;
      }

      &_lastPublished {
        display: flex;
        align-items: center;
        gap: 32px;

        @media screen and (max-width: 768px) {
          flex-direction: column;
        }

        & > img {
          width: 50%;
          border-radius: 4px;

          @media screen and (max-width: 768px) {
            width: 100%;
          }
        }

        &_description {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 4px;

          .date {
            @include text(rgba(255, 255, 255, 0.50), 14px, 600, 171.429%);
            margin-bottom: 4px;
          }

          .title {
            @include text(#D9D9D9, 18px, 700, 133.333%);
          }

          .content {
            @include text(#D9D9D9, 16px, 400, 150%);
          }

          .button {
            width: 100%;
            margin-top: 32px;
          }
        }
      }
    }

    &_divider {
      width: 100%;
      height: 1px;
      background: var(--border);
    }

    &_blogs {
      display: flex;
      flex-direction: column;
      gap: 56px;

      &_categories {
        display: flex;
        gap: 24px;

        &_button {
          padding: 8px 24px;
          border-radius: 4px;
          transition: 0.3s;
          border: 1px solid var(--white);
          @include text(var(--white), 16px, 500, 150%);

          &.active {
            color: var(--background-dark);
            background: var(--white);
            transition: 0.3s;
          }
        }
      }

      &_cards {
        display: flex;
        flex-wrap: wrap;
        gap: 56px 32px;

        @media screen and (max-width: 425px) {
          gap: 32px;
        }
      }
    }
  }
}
