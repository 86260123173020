@import "src/utils/reset";

.wrapper {
  @include page_box;

  &_errorPage {
    @include container_max_width;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;

    &_description {
      @include flex_center;
      flex-direction: column;
      gap: 24px;

      &_404 {
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: var(--white);
        -webkit-text-fill-color: transparent;
        @include text(var(--white), 96px, 400, 133.333%);
      }

      &_text {
        @include text(var(--white), 30px, 600, 133.333%);
        text-align: center;
      }
    }
  }
}
