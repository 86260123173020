@import "src/utils/reset";

.userStatistics {
  padding: 24px;
  border-radius: 4px;
  background: rgba(29, 29, 29, 0.80);
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (max-width: 768px) {
    padding: 24px 16px;
  }

  &_level {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 8px;

    &_number {
      @include text(var(--white), 18px, 500, 133.333%);
    }

    //&_month {
    //  display: flex;
    //  align-items: center;
    //  gap: 8px;
    //
    //  &_label {
    //    @include text(var(--white), 14px, 500, 171.429%);
    //  }
    //}
  }

  &_divider {
    width: 100%;
    height: 1px;
    background: var(--border);
  }

  &_pointsCircular {
    width: 100%;
    @include flex_center;
    flex-wrap: wrap;
    gap: 16px;

    &_progressBar {
      width: 150px;
      height: 150px;
    }

    &_points {
      display: flex;
      flex-direction: column;
      gap: 32px;
      flex: 1;

      &_count {
        display: flex;
        flex-direction: column;
        gap: 8px;
        flex: 1;

        &_wrapper {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 8px;

          .subtitle {
            @include text(var(--white), 14px, 400, 171.429%);
          }

          .count {
            @include text(var(--white), 14px, 500, 171.429%);
          }
        }
      }

      &_invitation {
        display: flex;
        flex-direction: column;
        gap: 8px;

        &_subtitle {
          @include text(var(--white), 16px, 500, 150%);
          white-space: nowrap;
        }

        &_button {
          padding: 8px 12px;
          width: 100%;
          border-radius: 4px;
          border: 1px solid var(--border);
          display: flex;
          align-items: center;
          justify-content: space-between;
          transition: 0.3s;

          &:hover, &:active {
            transform: scale(0.97);
            transition: 0.3s;
          }

          & > p {
            @include text(var(--white), 14px, 400, 171.429%);
          }

          &.copied {
            & > p {
              @include text(var(--main-green), 14px, 400, 171.429%);
            }
          }
        }
      }
    }
  }

  &_nextLevel {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &_title {
      @include text(var(--white), 18px, 500, 133.333%);
    }

    &_progress {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }

  //&_bonus {
  //  padding: 8px 10px 8px 19px;
  //  border-radius: 4px;
  //  background: rgba(35, 155, 133, 0.15);
  //  display: flex;
  //  flex-direction: column;
  //  gap: 8px;
  //
  //  &_item {
  //    position: relative;
  //    @include text(#999, 14px, 500, 171.429%);
  //
  //    &:before {
  //      content: '';
  //      position: absolute;
  //      width: 3px;
  //      height: 3px;
  //      background: #999;
  //      top: 50%;
  //      left: -11px;
  //      border-radius: 50%;
  //      transform: translateY(-50%);
  //    }
  //
  //    & > span {
  //      @include text(var(--main-green), 14px, 500, 24px);
  //    }
  //  }
  //}
}
