@import "src/utils/reset";

.searchBar {
  min-width: 40%;
  position: relative;

  &.mobile {
    width: 100%;
    height: 50px;
  }

  &_search {
    min-width: 40%;
    padding: 8px 12px;
    border-radius: 4px 4px 0 0;
    background: var(--background-light);
    display: flex;
    align-items: center;
    gap: 8px;

    &.mobile {
      width: 100%;
      height: 50px;
    }

    &_input {
      width: 100%;
      @include text(#8F8F8F, 16px, 400, 150%);

      &::placeholder {
        @include text(#8F8F8F, 16px, 400, 150%);
      }
    }

    button {
      width: 20px;
      height: 20px;

      &:before, &:after {
        content: '';
        position: absolute;
        width: 1.5px;
        height: 18px;
        background-color: #8F8F8F;
        cursor: pointer;
      }

      &:before, &:after {
        top: 12px;
        right: 24px;
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }

      &.mobile {
        &:before, &:after {
          top: 16px;
          right: 24px;
        }

        &:before {
          transform: rotate(45deg);
        }

        &:after {
          transform: rotate(-45deg);
        }
      }
    }
  }
}
