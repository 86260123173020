@import "src/utils/reset";

.item {
  max-width: 300px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  transition: 0.3s;

  &:hover {
    transform: scale(0.95);
    transition: 0.3s;
  }

  @media screen and (max-width: 425px) {
    width: 100%;
    max-width: none;
  }

  & > img {
    border-radius: 4px;
    max-height: 445px;
    width: 100%;
    object-fit: cover;
  }

  &_description {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &_category {
      @include text(var(--white), 14px, 400, 171.429%);
      text-transform: uppercase;
    }

    &_title {
      @include text(var(--white), 16px, 700, 150%);
    }

    &_content {
      @include text(#888, 14px, 400, 171.429%);
    }
  }
}
