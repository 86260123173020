.rs-input-group.rs-input-group-inside {
  border-color: var(--border);

  & > input {
    background: transparent;
    color: var(--white);
  }
}

.rs-picker:not(.rs-picker-disabled):hover, .rs-input-group:focus-within,
.rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus,
.rs-input-group:not(.rs-input-group-disabled):hover {
  border-color: var(--border);
}
